import React, { useState } from 'react'
import Filters from '../../atoms/Filters'
import { ContactRequest, DocRef } from '../../types/dreico.types'
import ListOfRequests from './ListOfRequests'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ContactCard from '../ContactCard'
import ConnectContactsForm from '../../forms/ConnectContactsForm'
import ConnectModal from './ConnectModal'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const ConnectContact = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect' })

  const tabs = [
    {
      label: t("sections.newRequests"),
      value: 'new'
    },
    {
      label: t("sections.processedRequests"),
      value: 'processed'
    },
    {
      label: t("sections.closedRequests"),
      value: 'finished'
    }
  ]

  const [activeTab, setActiveTab] = useState(tabs[0].value)
  const [activeRequest, setActiveRequest] = useState<ContactRequest>()
  const [contactSelected, setContactSelected] = useState<DocRef>()

  return (
    <>
      {contactSelected && activeRequest
        ?
        (<>
          <Box pt={3}>
            <Typography variant="h5">
              {t("selectContacts.titleOne.title")}
            </Typography>
            <ContactCard contactRef={activeRequest.requesterId} sx={{ pt: 4, pb: 2 }} hideButton={true} />
            <Divider>{t("selectContacts.titleOne.and")}</Divider>
            <ContactCard contactRef={contactSelected} sx={{ py: 4 }} hideButton={true} />
            <ConnectContactsForm
              requesterId={activeRequest.requesterId}
              connectedUserId={contactSelected}
              requestId={activeRequest._id}
              request={activeRequest}
              onBack={() => setContactSelected(undefined)} />
          </Box>
        </>)
        :
        <>
          <Filters
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ListOfRequests
            filter={activeTab as 'new' | 'processed' | 'finished'}
            setActiveRequest={setActiveRequest}
          />
          <ConnectModal
            open={!!activeRequest}
            request={activeRequest}
            handleSubmit={setContactSelected}
            onClose={() => setActiveRequest(undefined)}
          />
        </>
      }

    </>
  )
}

export default ConnectContact