import React, { useState, useContext, useMemo } from 'react'
import Box from '@mui/material/Box'
import { UserContext } from '../../contexts/UserContext'
import { Connection } from '../../types/dreico.types'
import ListOfConnections from './ListOfConnections'
import Filters from '../../atoms/Filters'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { styles } from '../../styles/styles'
import EmptyConnections from '../EmptyListPlaceholder/EmptyConnections'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import { returnsResults } from './returnsResults'

const chipStyle = {
  fontSize: '13px',
  fontWeight: '500',
  padding: '0 3px',
  backgroundColor: styles.LIGHT_TEXT_SECONDARY,
  color: styles.WHITE,
  marginRight: 1,
  marginBottom: 1,
  "&:hover": {
    backgroundColor: styles.LIGHT_TEXT_PRIMARY,
  },
  "&:last-of-type": {
    marginRight: 0,
  }
}

const chipStyleOutlined = {
  fontSize: '13px',
  fontWeight: '500',
  padding: '0 3px',
  marginRight: 1,
  marginBottom: 1,
  "&:last-of-type": {
    marginRight: 0,
  }
}

const Connections = () => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections' })
  const squareTabs = useMemo( 
    () => [
      {
        label: t('sections.new'),
        value: 'pending'
      },
      {
        label: t('sections.success'),
        value: 'accepted',
      },
      {
        label: t('sections.noSuccess'),
        value: 'rejected',
      },
    ],
    [t]
  )
  const roundTabs = useMemo( 
    () => [
      {
        label: t('filters.filterAll'),
        value: 'all',
      },
      {
        label: t('filters.filterByYou'),
        value: 'requester',
      },
      {
        label: t('filters.filterByElse'),
        value: 'connectedUser',
      },
    ],
    [t]
  )

  const { connections, user } = useContext(UserContext)
  const [squareFilter, setSquareFilter] = useState(squareTabs[0].value)
  const [roundFilter, setRoundFilter] = useState(roundTabs[0].value)

  const listedConnections = useMemo(() => {
    return connections.filter(connection => connection.connectorId !== user?._id)
  }, [connections, user?._id])

  const groupedConnections = useMemo(() => {
    
    return listedConnections.reduce((acc, connection) => {
      const requestId = connection.requestId
      if (acc[requestId]) {
        acc[requestId].push(connection)
      } else {
        acc[requestId] = [connection]
      }
      return acc
    }, {} as { [key: string]: Connection[] })
  }, [listedConnections])

  return (
    <Box display="flex" flexDirection="column">
      <Filters tabs={squareTabs} activeTab={squareFilter} setActiveTab={setSquareFilter} />
      <Box>
        <Typography variant="subtitle2" pb={0.5}>
          {t('filters.instruction')}
        </Typography>
        <Box display="flex" mb={2} overflow={'auto'}>
          {roundTabs.map((filter) => (
            <Chip
              label={filter.label}
              sx={roundFilter === filter.value ? chipStyle : chipStyleOutlined}
              onClick={() => setRoundFilter(filter.value)}
              variant={roundFilter === filter.value ? 'filled' : 'outlined'} />
          ))}
        </Box>
      </Box>
      {listedConnections.length > 0 ?
        returnsResults({ 
          groupedConnections,
          roleFilter: roundFilter,
          evaluationFilter: squareFilter,
          user: user,
        }) ? groupedConnections && Object.keys(groupedConnections).length > 0
          &&  Object.keys(groupedConnections).map((key, index) => {
            return (
              <ListOfConnections
                connections={groupedConnections[key]}
                roleFilter={roundFilter}
                evaluationFilter={squareFilter} />
          )})
          : <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2} >
              <EmptyConnections isFiltered />
            </Box>
      : <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2} >
          <EmptyConnections />
        </Box> }
    </Box>
  )
}

export default Connections