import React, { useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../contexts/UserContext'
import { TranslateFunction } from '../../types/i18next'

const BusinessCards = () => {

  const { user } = useContext(UserContext)
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.businessType.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.businessType}
          </Typography>
        </CardContent>
      </Card>
      {user?.business?.name && <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.name.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.name}
          </Typography>
        </CardContent>
      </Card>}
      {user?.business?.position && <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.position.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.position}
          </Typography>
        </CardContent>
      </Card>}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.locations.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.location.map((loc) => (
              <Chip
                label={loc.description}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.industries.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.industries.map((ind) => (
              <Chip
                label={ind}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.tags.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.businessOntology.tags.map((tag) => (
              <Chip
                label={tag.name}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('form.business.description.title')}
          </Typography>
          <Typography variant="body1">
            {user?.business?.description}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default BusinessCards