import React from 'react'
import Box from '@mui/material/Box'
import Profile from '../../components/Profile'

const MyProfileView = () => {
  return (
    <Box>
      <Profile />
    </Box>
  )
}

export default MyProfileView