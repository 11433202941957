export const AppRoutes = {
    ROOT: "*",
    LOGIN: "/login",
    CONNECTOR_SIGNUP: "/connector-signup",
    SIGNUP: "/signup",
    RESET_PASSWORD: "/reset-password",
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    REQUESTS: "/requests",
    ANFRAGEN: "/anfragen",
    VERMITTELN: "/vermitteln",
    KONTAKTANFRAGE: "/kontaktanfrage",
    VERNETZUNGEN: "/vernetzungen",
    CONTACT_BOOK: "/kontaktbuch",
    SETTINGS: "/einstellungen",
    MAIL_ACTION: "/mailaction",
    PRIVACY_POLICY: "/datenschutz",
    IMPRINT: "/impressum",
    TERMS_OF_USE: "/nutzungsbedingungen",
    ADD_CONTACT: "/add-contact",
}