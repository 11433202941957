import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyConnectSVG } from '../../assets/empty_state_connect.svg'
import { TranslateFunction } from '../../types/i18next'
import EmptyList, { SpecificEmptyListProps } from './EmptyList'

const EmptyConnect = (props: SpecificEmptyListProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect' })

  const { isFiltered=false, ...overrides } = props
  const unfiltered = useMemo(
    () => ({
      title: t('connectEmptyHeadline'),
      text: t('connectEmptyText')
    }),
    [t]
  ) 
  const filtered = useMemo( () => ({
    title: t('connectEmptyFilterTitle'),
    text: t('connectEmptyFilterText')
  }), [t])
  return <EmptyList 
    { ...(isFiltered ? filtered: unfiltered)}
    {...overrides}
    placeHolderSVG={EmptyConnectSVG}
  />
}

export default EmptyConnect