import { Switch, SwitchProps, Typography, TypographyProps } from "@mui/material"
import { CSSProperties } from "react"

interface LabeledSwitchProps extends SwitchProps {
  label: string,
  boxStyles?: CSSProperties,
  labelProps?: TypographyProps
}

const LabeledSwitch = (switchProps: LabeledSwitchProps) => {
  const {
    label,
    boxStyles: boxStyleOverrides,
    labelProps: labelPropsOverrides,
    ...restProps
  } = switchProps

  const boxStyles: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...boxStyleOverrides
  }

  const labelProps: TypographyProps = {
    variant: "body1",
    ...labelPropsOverrides
  }
  return (
    <div
      style={{
        ...boxStyles
      }}
    > 
      <Typography {...labelProps} >{label}</Typography>
      <Switch {...restProps} />
    </div>
  )
}
export default LabeledSwitch