import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConnectContact from '../../components/ConnectContact'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'


const VermittelnScreen = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect' })

  return (
    <Box>
      <Typography variant="h4" py={3}>
        {t("title")}
      </Typography>
      <ConnectContact />
    </Box>
  )
}

export default VermittelnScreen
