import React, { useContext, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ContactCard from '../ContactCard'
import Chip from '@mui/material/Chip'
import { Connection, ContactRequest } from '../../types/dreico.types'
import { UserContext } from '../../contexts/UserContext'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import NameToModal from '../Requests/NameToModal'
import { firebaseEvaluateConnection } from '../../services/user'
import Modal from '../../atoms/Modal'
import RequestContent from '../Requests/RequestContent'
import Check from '@mui/icons-material/Check'
import Cross from '@mui/icons-material/Close'
import ContactDataComponent from '../ContactCard/ContactDataComponent'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'

interface Props {
  evaluationFilter: string
  roleFilter: string
  connections: Connection[]
}

const ListOfConnections = (props: Props) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections' })

  const chiplabel = useMemo(
    () => [
      t('filters.filterByYou'), t('filters.filterByElse')
    ],
    [t]
  ) 
  const { connections, evaluationFilter, roleFilter } = props
  const { user } = useContext(UserContext)
  const { showSuccessMessage } = useContext(ShowMessageContext)
  const [modalOpened, setModalOpened] = useState(false)

  const userIsRequester = useMemo(() => connections[0].requesterId === user?._id, [connections, user?._id])
  const filteredConnections = useMemo(() => {
    const evaluationFiltered = connections.filter(c =>
      user?.connections?.find && user?.connections?.find(personalConnections => personalConnections.id === c._id)?.evaluation
      === evaluationFilter
    )
    if (roleFilter === 'all')
      return evaluationFiltered
    if (roleFilter === 'requester' && userIsRequester)
      return evaluationFiltered
    if (roleFilter === 'connectedUser' && !userIsRequester)
      return evaluationFiltered
    return []
  }, [connections, evaluationFilter, roleFilter, userIsRequester, user?.connections])

  const connectionRef = (id: string) => {
    return user?.connections?.find && user?.connections?.find((connection) => connection.id === id)
  }

  return (
    <>
      { filteredConnections.length === 0
        ? <></>
        : <Box pb={3}>
          <Box pb={1} mb={1}>
            <Chip variant="outlined" label={userIsRequester ? chiplabel[0] : chiplabel[1]} />
          </Box>
          {(
            <>
              <Typography variant="h6" pl={.5}>
                {filteredConnections[0].requestSnapshot?.title}
              </Typography>
              <Button
                sx={{ mb: 1 }}
                variant="text"
                onClick={() => setModalOpened(true)}
                endIcon={<ArrowForward />}
              >
                {t('buttonSeeRequest')}
              </Button>
              <Modal open={modalOpened} onClose={() => setModalOpened(false)}>
                <RequestContent request={filteredConnections[0].requestSnapshot as ContactRequest} isModal={true} handleClose={() => setModalOpened(false)} sx={{maxWidth: '900px'}} />
              </Modal>
            </>)}

          {filteredConnections.map((connection, index) => (
            <Card key={connection._id + index} sx={{ mb: 2 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <ContactCard contactRef={userIsRequester ? connection.connectedUserId : connection.requesterId} />
                </Box>
                <Box pt={4}>
                  <Box pb={3}>
                    <NameToModal id={connection.connectorId} />
                    <Typography variant="body2">
                      {connection.message}
                    </Typography>
                  </Box>
                  <ContactDataComponent
                  fetch={true}
                  contactId={userIsRequester ? connection.connectedUserId : connection.requesterId}
                  sx={{ ml: 0, maxWidth: 'unset', paddingLeft: 0, paddingRight: 0 }}
                />
                  <Box>
                    <Typography variant="subtitle1">
                      { t('connectionProfileShort.feedBackInstruction')}
                    </Typography>
                    <Typography variant="body2">
                      { t('connectionProfileShort.feedBackSubText')}
                    </Typography>
                    <Box display="flex" justifyContent={['flex-end']} py={1}>
                      <Button
                        variant="outlined"
                        disabled={connectionRef(connection._id)?.evaluation === 'accepted' ? true : false}
                        startIcon={connectionRef(connection._id)?.evaluation === 'rejected' ? <Cross /> : undefined}
                        onClick={() => {
                          firebaseEvaluateConnection(
                            connection._id,
                            user?.connections,
                            'rejected',
                            () => { showSuccessMessage(t('connectionProfileShort.successMessageNoSuccess'))})
                        }}> {t('connectionProfileShort.buttonNoSuccess')} </Button>
                      <Box px={1} />
                      <Button
                        variant="contained"
                        disabled={connectionRef(connection._id)?.evaluation === 'rejected' ? true : false}
                        startIcon={connectionRef(connection._id)?.evaluation === 'accepted' ? <Check /> : undefined}
                        onClick={() => {
                          firebaseEvaluateConnection(
                            connection._id,
                            user?.connections,
                            'accepted',
                            () => { showSuccessMessage(t('connectionProfileShort.successMessageSuccess'))})
                        }}> {t('connectionProfileShort.buttonSuccess')} </Button>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>)
          )}
        </Box>
    }
    </>
  )
}

export default ListOfConnections