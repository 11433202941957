import React, { useContext, createRef, useMemo, RefObject } from 'react'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import FormikWithShowingErrors from "./FormikWithShowingErrors";
import resize from '../utility/image-processing/resizer'
import UserAvatar from '../components/Avatar/Avatar'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../types/i18next'
import { ShowMessageContext } from '../contexts/ShowMessageContext'

const phoneRegExp = /(\(?([\d \-)–+/\\(]+){6,}\)?([ .\-–/]?)([\d]+))/


interface ProfileFormProps {
  setEditMode: (editMode: boolean) => void
}

const ProfileForm = (props: ProfileFormProps) => {

  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile.form' })

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(t('personal.firstNameErrorRequired')),
    lastName: yup
      .string()
      .required(t('personal.lastNameErrorRequired')),
    email: yup
      .string()
      .email(t('personal.emailErrorInvalid'))
      .required(t('personal.emailErrorRequired')),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, { message: t('personal.phoneNumberInvalid'), excludeEmptyString: true })
  })


  const { setEditMode } = props
  const { user } = useContext(UserContext)
  const { showSuccessMessage } = useContext(ShowMessageContext)

  const pictureUploadRef = useMemo((): RefObject<HTMLInputElement> => createRef(), [])

  const uploadPicture = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pictureFile = pictureUploadRef.current?.files?.[0]
    if (!pictureFile || !user) return
    const result = await resize({ original_data: pictureFile })
    const { firstName, lastName } = user
    await firebaseSetUser({
      values: {
        firstName,
        lastName,
        avatar: result
      },
      actionType: 'updateProfilePicture',
      user: user
    })
  }

  console.log(user)

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" pb={3}>
          {t('personal.title')}
        </Typography>
        <FormikWithShowingErrors
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            phoneNumber: user?.phoneNumber || '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            console.log(values)
            user && await firebaseSetUser({
              values: values,
              actionType: 'updateProfileData',
              user: user,
              onSuccess: () => { 
                setEditMode(false)
                showSuccessMessage(t('personal.successMessage'))
              }
            })
          }}
        >
          {formik => (
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <Box pb={3}>
                <UserAvatar sx={{ height: 100, width: 100 }} user={user} />
                <Button component="label">
                  <>
                    {t('personal.pictureTextReplace')}
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      ref={pictureUploadRef}
                      onChange={uploadPicture}
                    />
                  </>
                </Button>
              </Box>

              <Typography variant="h5">Vorname</Typography>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label={t('personal.firstName')}
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Nachname</Typography>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label={t('personal.lastName')}
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Email</Typography>
              <TextField
                fullWidth
                disabled
                id="email"
                name="email"
                label={t('personal.email')}
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Typography variant="h5">Telefonnummer</Typography>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label={t('personal.phoneNumber')}
                type="text"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                margin="normal"
                sx={{ mt: 1, mb: 3 }}
              />
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={() => setEditMode(false)}>{t('formCancelButton')}</Button>
                <Box px={1} />
                <Button color="primary" variant="contained" type="submit">
                  {t('formSaveButton')}
                </Button>
              </Box>
            </Box>
          )
          }
        </FormikWithShowingErrors>
      </CardContent>
    </Card>
  )
}

export default ProfileForm