import React, { useContext, useState } from 'react'
import algoliasearch from 'algoliasearch'
import {
  InstantSearch,
  Hits,
} from 'react-instantsearch-hooks-web';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ContactCard from '../ContactCard';
import './contactSearch.css'
import { UserContext } from '../../contexts/UserContext';
import CustomSearchBox from './CustomSearchBox';
import CustomRequestHit from './CustomRequestHit';
import config from '../../config';

const algoliaIndex = config.algoliaIndex

const searchClient = algoliasearch('QPKOKCLH8G', 'c9b89f402177ab22fe37b1f039f1d767')

interface HitProps {
  hit: any
}

const ContactBookHit = ({ hit }: HitProps) => (
  <>
    <ContactCard
      contactProp={hit}
      contactRef={hit._id}
      sx={{ py: 4 }}
    />
    <Divider />
  </>
)

interface ContactSearchProps {
  filter: string
  isContactRequest?: boolean
  formik?: any
}

const ContactSearch = (props: ContactSearchProps) => {
  const { filter, isContactRequest, formik } = props
  const { user } = useContext(UserContext)

  const [searchValue, setSearchValue] = useState('')

  return (
    <Box className="contact-search">
      <InstantSearch indexName={algoliaIndex} searchClient={searchClient} >
        <CustomSearchBox setSearchValue={setSearchValue} />
        {isContactRequest &&
          <Typography variant="body2" textAlign="end" pb={1}>
            Connectoren <br />
            auswählen
          </Typography>}
        <Divider />
        {!isContactRequest ? <Hits hitComponent={ContactBookHit} transformItems={
          hit => user?.contacts ? hit
            .filter(c => (!filter ? true :
              filter === 'connector' ?
                c._type === filter || c._type === 'admin' :
                c._type === filter))
            .filter(c => user.contacts?.find(contact => contact === c._id))
            : []
        } /> :
          <CustomRequestHit searchValue={searchValue} formik={formik} transformItems={
            hit => user?.contacts ? hit
              .filter(c => (!filter ? true :
                filter === 'connector' ?
                  c._type === filter || c._type === 'admin' :
                  c._type === filter))
              .filter(c => c._id !== user._id)
              .filter(c => user._type === 'user' ? user.contacts?.find(contact => contact === c._id) : true)
              : []
          } />}
      </InstantSearch>
    </Box>
  )
}

export default ContactSearch