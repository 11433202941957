import { Connector, RequestOntology, NetworkOntology } from "../types/dreico.types"

const calculateSimilarityScore = (requestOntology: RequestOntology, connectorNetworkOntology: NetworkOntology) => {

    const tagNamesRequest = requestOntology.tags.map(tag => tag.name)
    const tagNamesNetwork = connectorNetworkOntology.tags.map(tag => tag.name)

    const numberOfEqualTags = tagNamesRequest.filter(tagName => tagNamesNetwork.includes(tagName)).length
    const numberOfEqualIndustries = requestOntology.industries.filter(industry => connectorNetworkOntology.industries.includes(industry)).length
    return [numberOfEqualTags, numberOfEqualIndustries]
}

interface RecommendationVars {
    requestOntology: any
    connectors: Connector[]
}

const generateRecommendation = (vars: RecommendationVars) => {

    const { requestOntology, connectors } = vars

    const sorting: { [key: string]: number[] } = {}

    connectors.forEach(connector => {
        // calculate similarity score
        const connectorNetworkOntology = connector.networkOntology
        if (!connectorNetworkOntology) {
            sorting[connector._id] = [0, 0]
        } else {
            const score = calculateSimilarityScore(requestOntology, connectorNetworkOntology)
            sorting[connector._id] = score
        }
    })

    const sortedConnectors = Object.keys(sorting).sort((a, b) => {
        const scoreA = sorting[a]
        const scoreB = sorting[b]
        if (scoreA[0] > scoreB[0]) {
            return -1

        } else if (scoreA[0] < scoreB[0]) {
            return 1
        } else {
            if (scoreA[1] > scoreB[1]) {
                return -1
            } else if (scoreA[1] < scoreB[1]) {
                return 1
            } else {
                return 0
            }
        }
    })

    // return sortedConnectors
    return sortedConnectors.map(connectorId => connectors.find(connector => connector._id === connectorId)) as Connector[]
}


export default generateRecommendation