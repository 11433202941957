import React from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { BusinessType, defaultBusinessTypes } from '../types/dreico.types'
import { CustomAutocompleteProps } from '../types/forms.types'
import { TranslateFunction } from '../types/i18next'
import { useTranslation } from 'react-i18next'

interface BusinessTypeInputProps extends CustomAutocompleteProps {
  value: BusinessType[] | []
  error?: boolean
  helperText?: string[] | string | false
}

const BusinessTypeInput = (props: BusinessTypeInputProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.requestDescription' })

  return (
    <Autocomplete
      multiple
      id="businessType"
      value={props.value || []}
      onChange={props.onChange}
      options={[...defaultBusinessTypes, 'Egal']}
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((industryName, index) => (
          <Chip
            label={industryName}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("requestedProfile.requestedBusinessType.requestedBusinessTypePlaceholder")}
          placeholder=""
          name="businessType"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}

export default BusinessTypeInput