import React from 'react'
import { Box, Container } from '@mui/material'
import { Children } from '../types/react.types'

interface LayoutProps extends Children { }

const Layout = (props: LayoutProps) => {

  const { children } = props

  return (
    <>
      <Container disableGutters maxWidth="lg" sx={{ color: 'text.primary', backgroundColor: 'background.default', position: 'relative' }}>
        <Box px={2} py={3}>
          {children}
        </Box>
      </Container>
    </>
  )
}

export default Layout