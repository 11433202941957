import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { ContactRequest } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ContactCard from '../ContactCard'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import Fab from '@mui/material/Fab'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider'
import Card from '@mui/material/Card'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import InviteModal from "../InviteModal";

interface ConnectModalProps {
  open: boolean
  request?: ContactRequest,
  handleSubmit: Function,
  onClose: () => void,
}

const ConnectModal = (props: ConnectModalProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect' })
  const { request, handleSubmit, onClose, open } = props;

  const { user } = useContext(UserContext)
  const [, setOpen] = useState<boolean>(true);

  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  const _onClose = () => {
    setOpen(false);
    onClose();
  }

  return (
    <Modal open={open} onClose={_onClose}>
      <Box px={2} sx={{
        boxSizing: 'border-box',
        backgroundColor: 'background.default',
        width: '100%',
        minHeight: '100%',
      }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ pt: 2, pl: 1, pb: 3 }}
        >
          <Typography variant="h4" sx={{ pt: 1, width: '5rem' }}>
            {t("title")}
          </Typography>
          <IconButton onClick={() => _onClose()}>
            <Close />
          </IconButton>
        </Box>

        <Divider />
        <Box py={3} />
        {/* <Box display="flex" flexDirection={"column"} alignItems="center">
          <Typography variant="h5" sx={{ pt: 1, pb: 3 }}>
            {request?.title} a
          </Typography>
          {request &&
            <ContactCard
              contactRef={request?.requesterId}
              hideButton={true}
              sx={{ flexDirection: 'column' }}
            />}
        </Box> */}

        <Box>
          <Typography pb={2}>
            {t("selectContacts.inviteSubHeadline")}
          </Typography>
          <Box display="flex" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
            <Fab variant="extended" color="primary" onClick={() => setInviteModalOpen(true)} >
              <PersonAddIcon sx={{ pr: 1 }} />
              {t("selectContacts.inviteConnectionButton")}
            </Fab>
          </Box>
          <InviteModal request={request} prompt={t("selectContacts.inviteConnection.inviteDescription")} open={inviteModalOpen} handleClose={() => setInviteModalOpen(false)} />
        </Box>

        <Box py={3} />
        <Divider />

        <Box py={3}>
          <Typography variant="h6" mb={2}>
            {t("selectContacts.referContactSubHeadline")}
          </Typography>
          <Typography variant="body2">
            {t("selectContacts.description")}
          </Typography>
          {user?.contacts?.filter(contactId => contactId !== request?.requesterId).map(contactId => {
            const alreadyConnected = request?.connectedUserIds?.includes(contactId)
            return (
              <Card sx={{ my: 2, p: 2 }}>
                <ContactCard contactRef={contactId} sx={{ opacity: alreadyConnected ? 0.6 : 1 }} />
                {/* <Button variant="contained" component={Link} to={`/vermitteln/${r._id}/${c}`}>Kontakt vorschlagen</Button> */}
                {alreadyConnected ?
                  <Typography variant="body2">{t("selectContacts.connectedByOtherNote")}</Typography>
                  : (
                    <Box display="flex" justifyContent={['flex-end', 'flex-end', 'flex-start']}>
                      <Button variant="outlined" onClick={() => handleSubmit(contactId)}>
                        {t("selectContacts.connectButton")}
                      </Button>
                    </Box>
                  )
                }
              </Card>)
          })}
        </Box>
      </Box>
    </Modal>
  )
}

export default ConnectModal