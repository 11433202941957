import { useContext } from 'react'
import Avatar from '../Avatar/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { UserContext } from '../../contexts/UserContext'
import Mail from '@mui/icons-material/Mail'
import Call from '@mui/icons-material/Call'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const ProfileCard = () => {

  const { user } = useContext(UserContext)
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" pb={2}>
          {t('form.personal.title')}
        </Typography>
        <Box display={'flex'}>
          <Avatar sx={{ height: 100, width: 100 }} user={user}></Avatar>
          <Box pl={2}>
            <Typography variant="h6" pb={.5}>
              {user?.firstName} {user?.lastName}
            </Typography>
            {user?.email && <Box display="flex" alignItems="center">
              <Mail sx={{ width: '1rem', height: '1rem', mr: 0.5 }} />
              <Typography variant="body2">
                {user?.email}
              </Typography>
            </Box>}
            {user?.phoneNumber && <Box display="flex" alignItems="center">
              <Call sx={{ width: '1rem', height: '1rem', mr: 0.5 }} />
              <Typography variant="body2">
                {user?.phoneNumber}
              </Typography>
            </Box>}
          </Box>
        </Box>
      </CardContent>
    </Card >
  )
}

export default ProfileCard