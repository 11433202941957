import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { UserContext } from '../../contexts/UserContext'
import { Button, Card, Hidden, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import ReactMarkdown from 'react-markdown'
import DashboardMarkdown from '../../pages/dashboard.md'
import { TranslateFunction } from '../../types/i18next';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoDesktop } from '../../assets/infografik-desktop.svg'
import { ReactComponent as InfoMobile } from '../../assets/infografik-mobile.svg'
import { ReactComponent as InfoTablet } from '../../assets/infografik-tablet.svg'

const Dashboard = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'welcomeScreenText' })

  const { user } = useContext(UserContext)
  const navigate = useNavigate();

  const [dashboardMarkdown, setDashboardMarkdown] = useState('')

  useEffect(() => {
    fetch(DashboardMarkdown)
      .then(response => response.text())
      .then(text => {
        setDashboardMarkdown(text)
      })
  }, [setDashboardMarkdown])

  return (
    <>
      <Box
        sx={{
          m: [0, 0],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {user && !user.business && <Card sx={{ py: 3, px: [4], mb: '4rem', backgroundColor: '#536DFE', color: '#fff', textAlign: 'center' }} variant="elevation">
          <ReactMarkdown>
            {dashboardMarkdown}
          </ReactMarkdown>
          <Box mt={4} />
          <Button variant="outlined" color="inherit" endIcon={<ArrowForward />} onClick={() => navigate('/profile')}>
            {t('welcomeButton')}
          </Button>
        </Card>}
        <Box pb={8}>
          <Typography variant="h6">
            {t('preTitle')}
          </Typography>
          <Typography variant="h4">
            {t('title')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} alignItems="center">
          <ol style={{
            display: 'block',
            listStyleType: 'decimal-leading-zero',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            paddingInlineStart: '40px',
            width: '20rem',
            minWidth: '20rem',
          }}>
            <li style={{paddingBottom: '3rem'}}>
              <Box>
                <Typography variant="h6" pb={1}>
                  {t('stepOne.title')}
                </Typography>
                <Typography variant="body1">
                  {t('stepOne.text')}
                </Typography>
              </Box>
              
            </li>
            <li style={{ paddingBottom: '3rem' }}>
              <Box>
                <Typography variant="h6" pb={1}>
                  {t('stepTwo.title')}
                </Typography>
                <Typography variant="body1">
                  {t('stepTwo.text')}
                </Typography>
              </Box>
            </li>
            <li style={{ paddingBottom: '3rem' }}>
              <Box>
                <Typography variant="h6" pb={1}>
                  {t('stepThree.title')}
                </Typography>
                <Typography variant="body1">
                  {t('stepThree.text')}
                </Typography>
              </Box>
            </li>
            <li style={{ paddingBottom: '3rem' }}>
              <Box>
                <Typography variant="h6" pb={1}>
                  {t('stepFour.title')}
                </Typography>
                <Typography variant="body1">
                  {t('stepFour.text')}
                </Typography>
              </Box>
            </li>
          </ol>
          <Box width={'100%'} height={'100%'} pl={5} pr={[5,5,5,5,0]}>
            <Hidden lgDown>
              <InfoDesktop />
            </Hidden>
            <Hidden smDown lgUp>
              <Box width="100%" display="flex" justifyContent="center">
                <InfoTablet />
              </Box>
            </Hidden>
            <Hidden smUp>
              <Box width="100%" display="flex" justifyContent="center">
                <InfoMobile />
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;