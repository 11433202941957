import React, { useContext, useMemo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import FormikWithShowingErrors from './FormikWithShowingErrors'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ContactRequest, DocRef } from '../types/dreico.types'
import { firebaseCreateConnection } from '../services/connections'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../routes/Routes'
import { ShowMessageContext } from '../contexts/ShowMessageContext'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../types/i18next'



interface ConnectContactFormProps {
  requesterId: DocRef
  connectedUserId: DocRef
  requestId: DocRef
  request: ContactRequest
  onBack: () => void
}

const ConnectContactsForm = (props: ConnectContactFormProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect' })

  const validationSchema = useMemo( () => yup.object({
    message: yup.string().required(t('selectContacts.titleTwo.messageRequired')),
  }), [t])

  const { requesterId, connectedUserId, requestId, request, onBack } = props
  const navigate = useNavigate()

  const { showErrorMessage, showSuccessMessage } = useContext(ShowMessageContext)

  return (
    <Box sx={{ px: 2 }}>
      <Typography variant="h5" pb={1}>
        {t("selectContacts.titleTwo.title")}
      </Typography>
      <Typography pb={3} variant="body2">
        {t("selectContacts.titleTwo.description")}
      </Typography>
      <FormikWithShowingErrors
        initialValues={{
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          firebaseCreateConnection({
            values: values,
            request: request,
            requesterId: requesterId,
            connectedUserId: connectedUserId,
            requestId: requestId,
            onSuccess: () => {
              navigate(AppRoutes.DASHBOARD)
              showSuccessMessage(t("selectContacts.titleTwo.successMessage"))
            },
            showErrorMessage: showErrorMessage,
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit} display="flex" flexDirection="column" sx={{ pb: 2 }}>
            <TextField
              multiline
              minRows={4}
              id="message"
              name="message"
              label={t("selectContacts.titleTwo.placeholderMessage")}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Box py={3} />
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="text"
                startIcon={<ArrowBack />}
                onClick={onBack}
              >
                {t("selectContacts.titleTwo.backButton")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {t("selectContacts.titleTwo.connectButton")}
              </Button>
            </Box>

          </Box>
        )}
      </FormikWithShowingErrors>
    </Box>
  )
}

export default ConnectContactsForm