import React from 'react'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import FormikWithShowingErrors from '../../forms/FormikWithShowingErrors'
import { TranslateFunction } from '../../types/i18next'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import { theme } from '../../styles/theme'
import TextField from '@mui/material/TextField'
import { httpsCallable } from 'firebase/functions'
import { firebaseFunctions } from '../../services/firebase'
import AsyncSubmitButton from '../../atoms/AsyncSubmitButton'

const boxStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '1rem',

}

interface FeedbackModalProps {
  open: boolean
  handleClose: () => void
}

const FeebackModal = (props: FeedbackModalProps) => {

  const { open, handleClose } = props
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'feedback' })

  const validationSchema = yup.object({
    feedback: yup.string().required(t('validationMessages.required'))
  })

  const sendFeedbackEmail = httpsCallable(firebaseFunctions, 'sendFeedbackEmail')

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <FormikWithShowingErrors
        initialValues={{
          feedback: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          console.log(values)
          try {
            const res = await sendFeedbackEmail({
              feedback: values.feedback
            })
            console.log(res)
          } catch (error) {
            console.log(error)
          }
          setSubmitting(false)
          handleClose()
        }}
      >
        {(formik) => (
          <Box bgcolor={theme.palette.background.default}
            minWidth={[300, 400, 500]}
            sx={boxStyle} px={2} py={2}
            component="form" onSubmit={formik.handleSubmit} 
            >
      
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">{t('title')}</Typography>
                <IconButton>
                  <Close onClick={() => { handleClose() }} />
                </IconButton>
              </Box>
              <Typography variant="body1" pt={2} pb={3}>
                {t('description')}
              </Typography>
              <TextField
                fullWidth
                label={t('feedbackLabel')}
                name="feedback"
                multiline
                rows={8}
                variant="outlined"
                value={formik.values.feedback}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.feedback && Boolean(formik.errors.feedback)}
                helperText={formik.touched.feedback && formik.errors.feedback}
                placeholder={t('feedbackPlaceholder')}
              />
              <Box display="flex" justifyContent="flex-end" pt={2}>
                <AsyncSubmitButton
                  isLoading={formik.isSubmitting}
                  label={t('submit')}
                  variant="contained"
                />
              </Box>
            </Box>
        )}
      </FormikWithShowingErrors>
    </Modal>
  )
}

export default FeebackModal