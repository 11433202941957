import React, { useContext, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { UserContext } from '../../contexts/UserContext'
import Box from '@mui/material/Box'
import { ContactRequest } from '../../types/dreico.types'
import { Chip } from '@mui/material'
import ContactCard from '../ContactCard'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import DisplayedOntology from '../DisplayedOntology'
import { Timestamp } from 'firebase/firestore'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'
import EmptyConnect from '../EmptyListPlaceholder/EmptyConnect'

const sortRequestByTimestamp = (a: ContactRequest, b: ContactRequest) => {

  const timestampA = new Timestamp(a.createdAt.seconds, a.createdAt.nanoseconds)
  const timestampB = new Timestamp(b.createdAt.seconds, b.createdAt.nanoseconds)
  return timestampB.toMillis() - timestampA.toMillis()
}

interface ListOfRequestProps {
  filter: 'new' | 'processed' | 'finished'
  setActiveRequest: (request: ContactRequest) => void
}

const ListOfRequests = (props: ListOfRequestProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'requests' })

  const { user, requestsAsConnector } = useContext(UserContext)
  const { filter, setActiveRequest } = props

  const resultsExist = useMemo(() => requestsAsConnector.length > 0 && requestsAsConnector.some(
    request => {
      const rIds = requestsAsConnector.map(r => r._id)
      const userRequestsAsConnector = user?.requests?.filter(r => rIds.includes(r.id))

      if (filter === 'finished') {
        return request.status === 'closed'
      } else {
        if (filter === 'new') {
          return userRequestsAsConnector?.find(r => r.id === request._id)?.view === 'new'
        } else {
          return userRequestsAsConnector?.find(r => r.id === request._id)?.view === 'processed'
        }
      }
    })
    , [requestsAsConnector, user, filter])

  return (
    <>
      {!user ? <></> : requestsAsConnector.length ?
        resultsExist ? requestsAsConnector.sort(sortRequestByTimestamp)
          .map((request, index) => {
            if (user?.requests?.filter(r => (r.view === filter && request.status !== 'closed' ) || (filter === 'finished' && request.status === 'closed')).find(r => r.id === request._id)) {
              return (
                <Card sx={{ mb: 3 }} key={request.title + index}>
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" pb={1}>
                      <Typography variant="h5">
                        {request.title}
                      </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" pt={1}>
                      <Chip
                        label={request.status === 'closed' ? t("requestDetails.inactive") : t("requestDetails.active")}
                        color={request.status === 'closed' ? 'success' : 'primary'}
                        sx={{ height: '20px' }}
                      />
                      <Typography variant="body2" sx={{ pl: 1 }}>
                        {
                          typeof request.createdAt !== 'number' ?
                            new Timestamp(
                              request.createdAt.seconds, request.createdAt.nanoseconds).toDate().toLocaleDateString()
                            : (new Date(request.createdAt)).toLocaleDateString()
                        }
                      </Typography>
                    </Box>
                    <ContactCard contactRef={request.requesterId} sx={{ pt: 4, pb: 1 }} />
                    <Box display="flex"
                      justifyContent={['flex-end', 'flex-end', 'flex-start']}>
                      <Button
                        variant="contained"
                        onClick={() => { setActiveRequest(request) }}
                        endIcon={<ArrowForward />}
                        disabled={request.status === 'closed'}>
                        {t('requestDetails.referButton')}
                      </Button>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ pt: 4 }}>
                        {t('requestDetails.description')}
                      </Typography>
                      <Typography variant="body1" >
                        {request.description}
                      </Typography>
                      <DisplayedOntology
                        ontology={request.requestOntology}
                        title={t("requestDetails.searchedBusinessProfile")}
                        sx={{ pt: 3 }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              )
            } else {
              return <></>
            }
          }) :
        <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2} >
          <EmptyConnect isFiltered/>
        </Box> :
        <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2} >
          <EmptyConnect />
        </Box>
      }
    </>
  )
}

export default ListOfRequests
