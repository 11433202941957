import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ContactModal from '../ContactCard/ContactModal'
import { firebaseGetUserById } from '../../services/user'
import { Admin, Connector, User } from '../../types/dreico.types'

interface NameToModalProps {
  id: string
}

const NameToModal = (props: NameToModalProps) => {

  const { id } = props
  const [user, setUser] = useState<Admin | Connector | User | null>(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    firebaseGetUserById(id).then((user) => {
      setUser(user)
    })
  }, [id])

  return (
    <Box>
      {user &&
        <Box onClick={() => setOpen(!open)} sx={{ cursor: 'pointer', width: 'fit-content' }} >
          <Typography variant="body1" color="primary"> {user.firstName + ' ' + user.lastName} </Typography>
        </Box>
      }
      {user &&
        <ContactModal
          contact={user}
          open={open}
          handleClose={() => setOpen(false)}
          networkFirst={true}
        />}
    </Box>
  )
}

export default NameToModal