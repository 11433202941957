import React from 'react'
import { Box, Container } from '@mui/material'
import { Children } from '../types/react.types'
import Menu from '../components/Menu'

interface LayoutProps extends Children { }

const Layout = (props: LayoutProps) => {

  const { children } = props

  return (
    <>
      <Menu />
      <Container 
        disableGutters 
        sx={{ 
          color: 'text.primary', 
          backgroundColor: 'background.default', 
          position: 'relative',
          maxWidth: 'calc(1415px + 22rem) !important',
          margin: 0 }}>
        <Box px={2} py={3} ml={[0,0,0,'calc(22rem + 128px)']} mr={[0,0,0,'128px']}>
          {children}
        </Box>
      </Container>
    </>
  )
}

export default Layout