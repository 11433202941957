// import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormikWithShowingErrors from "../forms/FormikWithShowingErrors";
import { CircularProgress } from '@mui/material';
import { auth } from '../services/firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { ShowMessageContext } from "../contexts/ShowMessageContext";
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslateFunction } from '../types/i18next';


const ChangePasswordForm = () => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile.form.password' })

  const validationSchema = useMemo( () => yup.object({
    // email: yup
    //   .string()
    //   .email('Enter a valid email')
    //   .required('Email is required'),
    oldPassword: yup
      .string()
      .required(t('required')),
    newPassword: yup
      .string()
      .min(8, t('minLen'))
      .required(t('required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('confirmMatch'))
      .required(t('required')),
  }), [t]);
  

  const { currentUser } = auth
  const { showSuccessMessage, showErrorMessage } = useContext(ShowMessageContext)
  return (<>
    <FormikWithShowingErrors
      initialValues={{
        //email: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ oldPassword, newPassword }, { resetForm }) => {
        try {
          if (!currentUser?.email) {
            throw new Error("No User logged in.")
          }
          const credential = EmailAuthProvider.credential(
            currentUser.email,
            oldPassword
          );
          await reauthenticateWithCredential(currentUser, credential)
          await updatePassword(currentUser, newPassword)
          showSuccessMessage(t('changeSuccess'))
          resetForm()
        } catch (error) {
          showErrorMessage(t('changeNoSuccess'))
        }
      }}
    >
      {formik => (
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1, mb: 4, display: "flex", flexDirection: "column" }}
        >
          <TextField
            fullWidth
            id="oldPassword"
            name="oldPassword"
            label={t('oldPasswordPlaceholder')}
            type="password"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label={t('newPasswordPlaceholder')}
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            sx={{ mb: 3 }}
          />

          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label={t('confirmPasswordPlaceholder')}
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            sx={{ mb: 3 }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
            >
              {formik.isSubmitting ? <CircularProgress /> : "Speichern"}
            </Button>
          </Box>

        </Box>
      )}
    </FormikWithShowingErrors>
  </>)
}

export default ChangePasswordForm