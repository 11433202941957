import React from 'react'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import { styles } from '../styles/styles'

interface FiltersProps {
  tabs: {
    label: string
    value: string
  }[]
  activeTab: string
  setActiveTab: (tab: string) => void
}

const Filters = (props: FiltersProps) => {

  const { tabs, activeTab, setActiveTab } = props

  return (
    <Box pt={2} mb={3} sx={{ display: 'flex', flexFlow: 'row nowrap', whiteSpace: 'nowrap', overflow: 'auto' }} >
      {tabs && tabs.map(tab => (
        <Box key={tab.value} className={tab.value === activeTab ? 'active' : undefined} sx={{
          cursor: 'pointer',
          flexGrow: '1',
          display: 'inline-block',
          textAlign: 'center',
          width: 'fit-content',
          marginRight: '1rem',
          padding: '0 0.5rem',
          '&.active': {
            borderBottom: `2px solid ${styles.PRIMARY_COLOR_ACTIVE}`,
            color: styles.PRIMARY_COLOR_ACTIVE,
          }
          // '&:first-child': { h6: { paddingLeft: 0 } }
        }}
          onClick={() => {
            setActiveTab && setActiveTab(tab.value)
            // tab !== activeTab && setEditMode && setEditMode(false)
          }}>
          <Typography variant="subtitle2" px={0} py={0} sx={{}}>
            {tab.label}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default Filters
