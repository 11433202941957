import {useLocation, useNavigate} from "react-router";
import RegistrationForm from "../forms/RegistrationForm";
import {firebaseCreateUser} from "../services/user";
import {useMemo} from "react";

const ConnectorRegistrationScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();

    // once login succeeded:
    // if we have a from location in our Navigation string, redirect users there
    // if not, redirect them to the dashboard
    const redirectTo = useMemo<string>(() => location.state?.from?.pathname || "/dashboard", [location])

    return (
        <RegistrationForm onSubmit={
            async (values) => await firebaseCreateUser({
                values: values,
                isConnector: true,
                inviteId: null,
                onSuccess: () => navigate(redirectTo),
            })
        }/>
    )
}

export default ConnectorRegistrationScreen