import { Edit as EditIcon } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { styles } from '../../styles/styles'
import { TranslateFunction } from '../../types/i18next'

interface ProfileHeaderProps {
  tabs?: string[]
  activeTab?: string
  setActiveTab?: (tab: string) => void
  editMode: boolean
  setEditMode: (state: boolean) => void
}

const ProfileHeader = (props: ProfileHeaderProps) => {

  const { tabs, activeTab, setActiveTab, editMode, setEditMode } = props
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })

  return (
    <Box>
      <Typography variant="h4">
        {t('title')}
      </Typography>
      <Box py={2} mb={2} overflow={'auto'} whiteSpace={'nowrap'} mr={'-1rem'}>
        {tabs && tabs.map(tab => (
          <Box key={tab} className={tab === activeTab ? 'active' : undefined} sx={{
            display: 'inline-block',
            cursor: 'pointer',
            '&.active': {
              borderBottom: `2px solid ${styles.PRIMARY_COLOR_ACTIVE}`,
              color: styles.PRIMARY_COLOR_ACTIVE,
            },
            '&:first-child': { h6: { paddingLeft: 0 } }
          }}
            onClick={() => {
              setActiveTab && setActiveTab(tab)
              tab !== activeTab && setEditMode && setEditMode(false)
            }}>
            <Typography variant="subtitle2" px={2} >
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>
      {activeTab === t('sections.network') &&
        <Typography variant="body2" pb={3}>
          {t('form.network.introText')}
        </Typography>}
      {
        !editMode && <Box justifyContent="flex-end" display="flex" onClick={() => setEditMode(true)}>
          <Button variant="text" endIcon={<EditIcon />}>{t('form.formEditButton')}</Button>
        </Box>
      }
    </Box >

  )
}

export default ProfileHeader