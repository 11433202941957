import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import { DocRef } from '../../types/dreico.types'
import { HttpsCallable, httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { firebaseFunctions } from '../../services/firebase'
import { HttpsGetContactData } from '../../types/actions.types'
import { User, ContactData } from '../../types/dreico.types'
import {  LinearProgress, SxProps } from '@mui/material'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'

interface ContactDataProps {
  fetch: boolean
  contactId: DocRef
  sx?: SxProps
}

const ContactDataComponent = (props: ContactDataProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'contactBook' })

  const { fetch, contactId, sx } = props
  const [contactDetails, setContactDetails] = useState<Pick<User, 'email' | 'phoneNumber'>>()

  useEffect(() => {
    const getContactData: HttpsCallable<HttpsGetContactData, ContactData> = httpsCallable(firebaseFunctions, 'getContactData')
    const fetchData = async () => {
      const data: HttpsCallableResult<ContactData> = await getContactData({ contactId: contactId })
      setContactDetails(data.data)
    }
    fetch && !contactDetails && fetchData()
  }, [contactId, fetch, contactDetails])

  return (
    <>
      {contactDetails ? <Box maxWidth={800} m="auto" pb={4} px={2} sx={sx}>
        <Typography variant="subtitle1">
          {t('profileDetails.contactDetails')}
        </Typography>
        {contactDetails.email != null && <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            {contactDetails.email}
          </Typography>
          <IconButton size="small" onClick={() => {
            // @ts-ignore why is this not working?
            navigator.clipboard.writeText(contactDetails.email)
          }}
          >
            <ContentCopyOutlined sx={{ fontSize: '1.28rem' }} />
          </IconButton>
        </Box>}
        {contactDetails.phoneNumber && <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            {contactDetails.phoneNumber}
          </Typography>
          <IconButton size="small" onClick={() => {
            // @ts-ignore why is this not working?
            navigator.clipboard.writeText(contactDetails.phoneNumber)
          }}
          >
            <ContentCopyOutlined sx={{ fontSize: '1.28rem' }} />
          </IconButton>
        </Box>} 
      </Box> : 
        <Box maxWidth={800} m="auto" pb={4} px={2} sx={sx}>
          <Typography variant="subtitle1">
            {t('profileDetails.contactDetails')}
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" >
            <Box width={'100%'} mt={2}>
              <LinearProgress />
            </Box>
          </Box>
        </Box>}
    </>
  )
}

export default ContactDataComponent