import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '../Avatar/Avatar'
import { User, Connector, Admin, PlaceType } from '../../types/dreico.types'
import { SxProps } from '@mui/material'

interface UserInfoProps {
  contact: User | Connector | Admin
  children?: JSX.Element
  sx?: SxProps
}

const getLocationInfo = (locations: PlaceType[]): string => {
  if (locations.length === 0) {
    return ''
  }
  else if (locations.length === 1) {
    return locations[0].description || ""
  }
  else {
    return `${locations[0].description || ""} (+${locations.length - 1})`
  }
}

const UserInfo = (props: UserInfoProps) => {

  const { contact, children, sx } = props
  const locationsList = contact?.business?.businessOntology.location || []

  return (
    <Box display={'flex'} sx={sx}>
      <Box>
        <Avatar sx={{ m: 1, height: 60, width: 60 }} user={contact}></Avatar>
      </Box>
      <Box pl={2} pr={2}>
        <Typography variant="h6">
          {contact?.firstName} {contact?.lastName}
        </Typography>
        <Typography variant="body1" color="primary">
          {contact?._type !== 'user' && 'Connector'}
        </Typography>
        {contact?.business?.position &&
          <Typography variant="body1">
            {contact?.business?.position}
          </Typography>
        }
        {contact?.business?.name &&
          <Typography variant="body1" color="secondary">
            {contact?.business?.name}
          </Typography>
        }
        {contact?.business?.businessOntology &&
          <Typography variant="body1" color="secondary">
            {contact?.business?.businessOntology.businessType}
          </Typography>
        }
        {contact?.business?.businessOntology.location &&
          <Typography variant="body1" color="secondary" mb={2}>
            {getLocationInfo(locationsList)}
          </Typography>
        }
        {children}
      </Box>
    </Box>
  )
}

export default UserInfo
