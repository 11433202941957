import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'

export type SpecificEmptyListProps = {
  isFiltered?: boolean
  title?: string
  text?: string
}

interface EmptyListProps {
  title: string
  text: string
  placeHolderSVG: FC
}

const EmptyList = (props: EmptyListProps) => {
  const { title, text, placeHolderSVG: PlaceHolderSVG } = props
  return (
    <Box
      mt={8}
      mb={2}
      mx="auto"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 250,
        alignItems: 'center'
      }}
    >
      <PlaceHolderSVG />
      <Typography variant="h6" pt={2} pb={1} sx={{ fontSize: 18 }}>{title}</Typography>
      <Typography align="center" variant="body1">{text}</Typography>
    </Box >
  )
}

export default EmptyList