import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import ProfileForm from '../../forms/ProfileForm'
import ProfileCard from './ProfileCard'
import ProfileHeader from './ProfileHeader'
import BusinessForm from '../../forms/BusinessForm'
import BusinessCards from './BusinessCards'
import NetworkForm from '../../forms/NetworkForm'
import NetworkCards from './NetworkCards'
import { UserContext } from '../../contexts/UserContext'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const Profile = () => {

  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })
  const tabs = [t('sections.personal'), t('sections.business'), t('sections.network')]

  const [activeTab, setActiveTab] = useState(tabs[0])
  const [editMode, setEditMode] = useState(false)
  const { user } = useContext(UserContext)

  return (
    <Box>
      <ProfileHeader tabs={user?._type === 'user' ? [tabs[0], tabs[1]] : tabs} activeTab={activeTab} setActiveTab={setActiveTab} editMode={editMode} setEditMode={setEditMode} />
      <Box mt={4} pb={2}>
        {activeTab === tabs[0] ? editMode ? <ProfileForm setEditMode={setEditMode} /> : <ProfileCard /> :
          activeTab === tabs[1] ? editMode ? <BusinessForm setEditMode={setEditMode} /> : <BusinessCards /> :
            activeTab === tabs[2] ? editMode ? <NetworkForm setEditMode={setEditMode} /> : <NetworkCards /> : null}
      </Box>
    </Box>
  )
}

export default Profile