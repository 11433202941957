import React, { useEffect, useState } from 'react'
import TermsOfUse from '../../pages/terms-of-use.md'
import ReactMarkdown from 'react-markdown'
import LayoutWithoutMenu from '../../containers/LayoutWithoutMenu'

const TermsOfUseScreen = () => {

    const [pageMarkdown, setPageMarkdown] = useState('')

    useEffect(() => {
        fetch(TermsOfUse)
            .then(response => response.text())
            .then(text => {
                setPageMarkdown(text)
            })
    }, [])

    return (
        <LayoutWithoutMenu>
            <ReactMarkdown>
                {pageMarkdown}
            </ReactMarkdown>
        </LayoutWithoutMenu>
    )
}

export default TermsOfUseScreen