import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar'
import { Admin, Connector, User } from '../../types/dreico.types'
import pickRandomColor from '../../utility/css-helpers/pickRandomColor'

interface UserAvatarProps extends MuiAvatarProps {
  user: User | Connector | Admin | undefined
}



const UserAvatar = (props: UserAvatarProps) => {
  const { user, sx } = props
  const { firstName = "", lastName = "", avatar = undefined} = user ? user : {}
  const name = user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : undefined
  const initials = firstName[0] || lastName[0] 
    ? `${firstName[0]}${lastName[0]}`
    : lastName[0] || firstName[0] || undefined
  const bgcolor = pickRandomColor(name?.length || Math.round(Math.random()*1000))
  return (
    <MuiAvatar 
      {...props}
      sx={{bgcolor, ...sx}}
      alt={name} 
      src={avatar || " "}
    >
      { ! avatar && initials}
    </MuiAvatar>
  )
}

export default UserAvatar