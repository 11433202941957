import { DocumentReference, Timestamp } from "firebase/firestore"
import { firestore } from "firebase-admin"

export type DocRef = string

export interface EmailSettings {
    getEmailNotifications: boolean
}

export interface ContactData {
    email: string
    phoneNumber: string
}

export interface BaseUser {
    _type: 'user' | 'connector' | 'admin' // this has to be set by Account created and cannot be undefined
    _id: string
    business?: Business
    email?: string
    firstName: string
    lastName: string
    avatar?: string // Base64 encoded jpeg
    phoneNumber?: string
    userType?: 'user' | 'connector' | 'admin' // this has to be set by Account created and cannot be undefined
    inviteId?: string
    contacts?: DocRef[]
    requests?: RequestRef[]
    connections?: ConnectionRef[]
    emailSettings: EmailSettings
}

export interface User extends BaseUser {
    _type: 'user'
    userType: 'user'
}

export interface Connector extends BaseUser {
    _type: 'connector'
    networkOntology?: NetworkOntology
    userType: 'connector'
}

export interface Admin extends BaseUser {
    _type: 'admin'
    networkOntology?: NetworkOntology
    userType: 'admin'
}

export interface Business {
    _type: 'business'
    businessOntology: BusinessOntology
    description: string
    logo: string
    name: string
    position: string
    // website: string
}

export interface Ontology {
    _type: 'businessOntology' | 'networkOntology' | 'requestOntology'
    businessType: BusinessType | BusinessType[] | null
    industries: Inudstries[]
    location: PlaceType[]
    tags: Tag[]
}

export interface BusinessOntology extends Ontology {
    businessType: BusinessType | null
    _type: 'businessOntology'
}

export interface NetworkOntology extends Ontology {
    businessType: null
    _type: 'networkOntology'
}

export interface RequestOntology extends Ontology {
    businessType: BusinessType[]
    _type: 'requestOntology'
}

export const defaultBusinessTypes = [
    'Solopreneur',
    'Multipreneur',
    'Startup',
    'KMU',
    'Großunternehmen',
    'Verein',
    'Investor',
    'Sonstige',
] as const

export type BusinessType = typeof defaultBusinessTypes[number]// 'Solopreneur' | 'Intrapreneur' | 'SME' | 'Corporation' | 'NGO' | 'Government' | 'Other'

export type Tag = {
    _type: 'tag'
    name: string
}

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

export enum InviteStatus {
    unused = 'unused',
    accepted = 'accepted',
    declined = 'declined'
}

export interface Invite {
    inviter: DocRef,
    invitedMail: string,
    status: InviteStatus,
    message?: string,
    requestId?: string
    userType?: 'user' | 'connector'
}

export enum ContactBookInviteStatus {
    unused = 'unused',
    accepted = 'accepted',
    declined = 'declined'
}

export interface ContactBookInvite {
    addedById: string,
    addedUserEmail: string,
    status: ContactBookInviteStatus,
}

export interface InviteHttpRequestData {
    inviteId: undefined | null | string,
    invitedId: undefined | null | string
}

export interface IsEmailRegisteredHttpRequestData {
    email: string
}

export type ContactRef = DocumentReference<unknown>

export interface ContactRequest {
    _type: 'request'
    _id: string
    createdAt: Timestamp
    updatedAt?: Timestamp
    title: string
    desiredResult: string
    description: string
    status: 'open' | 'closed'
    requesterId: DocRef
    connectorIds: DocRef[]
    connectedUserIds?: DocRef[] | null
    requestOntology: RequestOntology
}

export interface RequestRef {
    id: DocRef
    view: 'new' | 'processed'
    role: 'requester' | 'connector'
}

export interface ConnectionRef {
    id: DocRef
    role: 'requester' | 'connector' | 'connectedUser'
    evaluation?: 'pending' | 'accepted' | 'rejected'
}

export interface Connection {
    _type: 'connection'
    _id: string
    createdAt: Timestamp | firestore.Timestamp
    connectorId: DocRef
    connectedUserId: DocRef
    message: string
    requesterId: DocRef
    requestId: DocRef
    requestSnapshot?: Omit<ContactRequest, 'connectedUserIds' | 'connectorIds'>
    allowRead: DocRef[]
}

export type Inudstries = typeof industries[number]

export const industries =
    ['Alternative Medizin',
        'Alternative Schlichtungsmethoden',
        'Ämter & Behörden',
        'Animation',
        'Architektur & Bauplanung',
        'Automobil',
        'Bankwesen',
        'Baugewerbe',
        'Baumaterialien',
        'Bekleidung & Mode',
        'Bergbau & Metallverarbeitung',
        'Berufliche Schulungen & Coaching',
        'Beziehung zu Regierungen',
        'Bibliothekswesen',
        'Bildungsmanagement',
        'Biotechnologie',
        'Buchhaltung',
        'Büromaterial und - ausstattung',
        'Chemie',
        'Computer - & Netzwerksicherheit',
        'Computer - Hardware',
        'Computer - Netzwerke',
        'Computer - Software',
        'Computerspiele',
        'Darstellende Künste',
        'Design',
        'Diplomatischer Dienst',
        'Druckwesen',
        'Einzelhandel',
        'E - Learning',
        'Elektro - /Elektronik-Herstellung',
        'Energieversorgung',
        'Erdöl & Energiewirtschaft',
        'Erneuerbare Energie & Umwelt',
        'Finanzdienstleistungen',
        'Fischereiwirtschaft',
        'Fluggesellschaften',
        'Forschung',
        'Fotografie',
        'Freizeiteinrichtungen & -dienste',
        'Fundraising',
        'Gebäudeverwaltung',
        'Gesundheit, Wellness & Fitness',
        'Gewerbeimmobilien',
        'Glas, Keramik & Zement',
        'Glücksspiel & Casinos',
        'Grafikdesign',
        'Großhandel',
        'Halbleiter',
        'Hoch - und Tiefbau',
        'Hochschulwesen',
        'Hotel - und Gaststättengewerbe',
        'Humanmedizin',
        'Immobilien',
        'Import und Export',
        'Industrielle Automatisierung',
        'Informationsdienste',
        'Internet',
        'Intl.Handel & Entwicklung',
        'Investment Banking',
        'IT und Services',
        'Juristische Dienstleistungen',
        'Justiz',
        'Kapitalmärkte',
        'Kino und Film',
        'Konsumgüter',
        'Kosmetik',
        'Krankenhaus & Gesundheitsbereich',
        'Kunstgewerbe',
        'Kunststoffe',
        'Lagerhaltung',
        'Landwirtschaft',
        'Lebensmittel & Getränke',
        'Lebensmittelherstellung',
        'Legislative',
        'Logistik und Beschaffung',
        'Luft - & Raumfahrt',
        'Luxusgüter & Schmuck',
        'Management von Nonprofit - Organisationen',
        'Management - Beratung',
        'Marketing und Werbung',
        'Marktforschung',
        'Maschinenbau & Betriebstechnik',
        'Medienproduktion',
        'Medizintechnik',
        'Milchwirtschaft',
        'Militär',
        'Möbel',
        'Museen und Kulturstätten',
        'Musik',
        'Nanotechnologie',
        'Öffentliche Ordnung',
        'Öffentliche Sicherheit',
        'Öffentlicher Dienst',
        'Öffentlichkeitsarbeit',
        'Online - Medien',
        'Outsourcing & Offshoring',
        'Pädagogik',
        'Paket - und Frachttransport',
        'Papier & Forstprodukte',
        'Personalberatung & -vermittlung',
        'Personalwesen',
        'Pharmazie',
        'Philanthropie',
        'Politische Organisationen',
        'Polizeidienst',
        'Presse',
        'Programmentwicklung',
        'Psychologie & Psychotherapie',
        'Rechtswesen',
        'Religiöse Einrichtungen',
        'Restaurants',
        'Rundfunk und Fernsehen',
        'Schiffbau',
        'Schöne Künste',
        'Schreiben und Redigieren',
        'Seefahrt',
        'Sicherheits - & Ermittlungsdienste',
        'Soziale Dienstleistungen',
        'Soziale Einrichtungen',
        'Sport',
        'Sportartikel',
        'Supermärkte',
        'Tabak',
        'Telekommunikation',
        'Textilien',
        'Think Tanks',
        'Tiermedizin',
        'Tourismus und Freizeit',
        'Transportwesen & Bahnverkehr',
        'Übersetzung und Lokalisierung',
        'Umweltdienste',
        'Unterhaltung',
        'Unterhaltungselektronik',
        'Venture Capital und Private Equity',
        'Veranstaltungsdienste',
        'Verbraucherdienste',
        'Verlagswesen',
        'Vermögensverwaltung',
        'Verpackungsindustrie',
        'Versicherungswesen',
        'Verteidigung & Raumfahrt',
        'Viehwirtschaft',
        'Weine und Spirituosen',
        'Wireless']

export const industries_en = [
    'Information Technology and Services',
    'Hospital & Health Care',
    'Construction',
    'Retail',
    'Education Management',
    'Financial Services',
    'Accounting',
    'Computer Software',
    'Higher Education',
    'Automotive',
    'Government Administration',
    'Marketing and Advertising',
    'Banking',
    'Health, Wellness and Fitness',
    'Real Estate',
    'Food & Beverages',
    'Telecommunications',
    'Oil & Energy',
    'Mechanical or Industrial Engineering',
    'Hospitality',
    'Primary/Secondary Education',
    'Electrical/Electronic Manufacturing',
    'Internet',
    'Insurance',
    'Consumer Services',
    'Medical Practice',
    'Human Resources',
    'Transportation/Trucking/Railroad',
    'Restaurants',
    'Civil Engineering',
    'Pharmaceuticals',
    'Design',
    'Logistics and Supply Chain',
    'Research',
    'Management Consulting',
    'Architecture & Planning',
    'Apparel & Fashion',
    'Food Production',
    'Law Practice',
    'Facilities Services',
    'Consumer Goods',
    'Non-Profit Organization Management',
    'Machinery',
    'Entertainment',
    'Chemicals',
    'Wholesale',
    'Arts and Crafts',
    'Farming',
    'Utilities',
    'Legal Services',
    'Sports',
    'Mining & Metals',
    'Airlines/Aviation',
    'Building Materials',
    'Leisure, Travel & Tourism',
    'Environmental Services',
    'Professional Training & Coaching',
    'Medical Devices',
    'Music',
    'Individual & Family Services',
    'Cosmetics',
    'Staffing and Recruiting',
    'Mental Health Care',
    'Graphic Design',
    'Industrial Automation',
    'Security and Investigations',
    'Biotechnology',
    'Aviation & Aerospace',
    'Business Supplies and Equipment',
    'Public Relations and Communications',
    'Import and Export',
    'Textiles',
    'Writing and Editing',
    'Consumer Electronics',
    'Media Production',
    'Renewables & Environment',
    'Broadcast Media',
    'International Trade and Development',
    'Military',
    'Computer Networking',
    'Civic & Social Organization',
    'Events Services',
    'Photography',
    'E-Learning',
    'Computer Hardware',
    'Computer & Network Security',
    'Defense & Space',
    'Furniture',
    'Fine Art',
    'Warehousing',
    'Printing',
    'Investment Management',
    'Outsourcing/Offshoring',
    'Publishing',
    'Information Services',
    'Law Enforcement',
    'Supermarkets',
    'Animation',
    'Maritime',
    'Executive Office',
    'Religious Institutions',
    'Government Relations',
    'Semiconductors',
    'Program Development',
    'Plastics',
    'Online Media',
    'Public Safety',
    'Packaging and Containers',
    'Commercial Real Estate',
    'Alternative Medicine',
    'Motion Pictures and Film',
    'Judiciary',
    'Performing Arts',
    'Computer Games',
    'Veterinary',
    'Luxury Goods & Jewelry',
    'Investment Banking',
    'Package/Freight Delivery',
    'International Affairs',
    'Market Research',
    'Recreational Facilities and Services',
    'Translation and Localization',
    'Wine and Spirits',
    'Capital Markets',
    'Public Policy',
    'Sporting Goods',
    'Newspapers',
    'Paper & Forest Products',
    'Venture Capital & Private Equity',
    'Wireless',
    'Libraries',
    'Gambling & Casinos',
    'Ranching',
    'Glass, Ceramics & Concrete',
    'Philanthropy',
    'Dairy',
    'Shipbuilding',
    'Museums and Institutions',
    'Think Tanks',
    'Political Organization',
    'Fishery',
    'Horticulture',
    'Tobacco',
    'Fund-Raising',
    'Railroad Manufacture',
    'Alternative Dispute Resolution',
    'Nanotechnology',
    'Legislative Office',
    'Mobile Games'
] as const