import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { UserContext } from '../../contexts/UserContext'
import RequestContent from './RequestContent'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import EmptyRequests from '../EmptyListPlaceholder/EmptyRequests'
import { Box } from '@mui/material'

const ListOfRequests = () => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'requests' })
  const { user, requestsAsRequester } = useContext(UserContext)
  return (
    <>
      <Typography variant="h5" pb={4}>{t("subTitle")}</Typography>
      {
        !user ? <></> : requestsAsRequester.length > 0
          ? requestsAsRequester.map((request, index) => (
            <Card sx={{ mb: 3 }} key={request.title + index}>
              <CardContent>
                <RequestContent request={request} />
              </CardContent>
            </Card>
          ))
          : <Box display="flex" flexDirection="column" alignItems="center" py={1} px={2} >
            <EmptyRequests />
          </Box>
      }
    </>
  )
}

export default ListOfRequests