import React, { useContext, useState } from 'react'
import MenuIcon from '@mui/icons-material/MenuRounded'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import DashboardRounded from '@mui/icons-material/DashboardRounded'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { auth } from '../../services/firebase'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router'
import { AppRoutes } from '../../routes/Routes'
import { Link } from 'react-router-dom'
import { AccountCircle, AccountTreeOutlined } from '@mui/icons-material'
import { UserContext } from '../../contexts/UserContext'
import Fab from '@mui/material/Fab'
import { ReactComponent as LogoWhite } from '../../assets/logo-white.svg';
import { ReactComponent as LogoDark } from '../../assets/logo-blue.svg';
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import { styles } from '../../styles/styles'
import { Hidden } from '@mui/material'
import FeebackModal from '../FeedbackModal'


interface MenuItem {
  title: any,
  link: {
    current: string,
    type: 'internal'
  }
  icon: JSX.Element
}

const vermittelnItem: MenuItem = {
  title: 'connect' as const,
  link: {
    current: AppRoutes.VERMITTELN,
    type: 'internal'
  },
  icon: <AccountTreeOutlined htmlColor="white" />
}

const vernetzungenItem: MenuItem = {
  title: 'connections' as const,
  link: {
    current: AppRoutes.VERNETZUNGEN,
    type: 'internal'
  },
  icon: <PersonAddIcon htmlColor="white" />
}

const anfragenItem: MenuItem = {
  title: 'requests' as const,
  link: {
    current: AppRoutes.ANFRAGEN,
    type: 'internal'
  },
  icon: <ListAltOutlinedIcon htmlColor="white" />
}

const contactBookItem: MenuItem = {
  title: 'contactBook' as const,
  link: {
    current: AppRoutes.CONTACT_BOOK,
    type: 'internal'
  },
  icon: <DashboardRounded htmlColor="white" />
}


const profileMenuItem: MenuItem = {
  title: 'profile' as const,
  link: {
    current: AppRoutes.PROFILE,
    type: 'internal'
  },
  icon: <AccountCircle htmlColor="white" />
}

const settingsMenuItem: MenuItem = {
  title: 'settings' as const,
  link: {
    current: AppRoutes.SETTINGS,
    type: 'internal'
  },
  icon: <SettingsIcon htmlColor="white" />
}

const feedbackMenuItem = {
  title: 'feedback' as const,
  icon: <ContentPasteIcon htmlColor="white" />
}

const Menu = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { user, resetUserData } = useContext(UserContext)
  const navigate = useNavigate()
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'menu' })

  const [openFeedback, setOpenFeeback] = useState(false)

  const drawer = (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" minWidth={'22rem'}>
      <List>
        <ListItem disablePadding>
          <Box py={1} px={2} flexGrow={1} onClick={() => navigate('/dashboard')} sx={{cursor: 'pointer'}}>
            <LogoWhite />
          </Box>
          <Box display={['block', 'block', 'block', 'none']}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <IconButton size="large" aria-label="close drawer" onClick={() => setMobileOpen(false)}>
                <CloseIcon htmlColor="white" />
              </IconButton>
            </ListItemIcon>
          </Box>
        </ListItem>

        <ListItem>
          <Box m={'1rem auto'}>
            <Link to={AppRoutes.KONTAKTANFRAGE} onClick={() => setMobileOpen(false)}>
              <Fab variant="extended" color="primary">
                <AddIcon sx={{ pr: 1 }} />
                Kontaktsuche starten
              </Fab>
            </Link>
          </Box>
        </ListItem>

        {user?._type !== 'user' && (
          <ListItem key={vermittelnItem.title} disablePadding>
            <Link to={vermittelnItem.link.current} style={{ color: 'white', width: '100%', textDecoration: 'none' }} onClick={() => setMobileOpen(false)}>
              <ListItemButton sx={{ padding: '.5rem 2rem .5rem 1.5rem' }}>
                <ListItemIcon>
                  {vermittelnItem.icon}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: styles.WHITE }} primary={t(vermittelnItem.title)} />
              </ListItemButton>
            </Link>
          </ListItem>
        )}

        {[vernetzungenItem, anfragenItem, contactBookItem].map((item, index) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.link.current} style={{ color: 'white', width: '100%', textDecoration: 'none' }} onClick={() => setMobileOpen(false)}>
              <ListItemButton sx={{ padding: '.5rem 2rem .5rem 1.5rem' }}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>

                <ListItemText primaryTypographyProps={{ color: styles.WHITE }} primary={t(item.title)} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <Box height={'4rem'} />
        {[profileMenuItem, settingsMenuItem].map((item, index) => (
          <ListItem key={item.title} disablePadding>
            <Link to={item.link.current} style={{ color: 'white', width: '100%', textDecoration: 'none' }} onClick={() => setMobileOpen(false)}>
              <ListItemButton sx={{ padding: '.5rem 2rem .5rem 1.5rem' }}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: styles.WHITE }} primary={t(item.title)} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton sx={{ padding: '.5rem 2rem .5rem 1.5rem' }} onClick={() => setOpenFeeback(true)}>
            <ListItemIcon>
              {feedbackMenuItem.icon}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: styles.WHITE }} primary={t(feedbackMenuItem.title)} />
          </ListItemButton>
          <FeebackModal open={openFeedback} handleClose={() => {setOpenFeeback(false)}} />
        </ListItem>

      </List>
      <List sx={{ marginBottom: '2rem' }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            signOut(auth)
            resetUserData()
            navigate('/')
          }} sx={{ padding: '.5rem 2rem .5rem 1.5rem' }}>
            <ListItemIcon>
              <LogoutIcon htmlColor="white" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: styles.WHITE }} primary={t('logout')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <Box>
      <Hidden lgUp>
        <AppBar position="sticky" sx={{ backgroundColor: 'background.paper', color: 'text.primary' }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, cursor: 'pointer' }} py={1} onClick={() => navigate('/dashboard')}>
              <LogoDark />
            </Box>
            <Hidden lgUp>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="open drawer"
                onClick={() => setMobileOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden lgDown>
        <Box my={5}></Box>
      </Hidden>

      <Hidden lgDown>
        <Drawer variant="permanent" open={mobileOpen} onClose={() => setMobileOpen(false)}>
          {drawer}
        </Drawer >
      </Hidden>
      <Hidden lgUp>
        <Drawer variant="temporary" open={mobileOpen} onClose={() => setMobileOpen(false)}>
          {drawer}
        </Drawer >
      </Hidden>

    </Box >
  )
}

export default Menu