import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import * as yup from 'yup'
import { Formik } from 'formik'
import RequestDescriptionForm from './RequestDescriptionForm'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import SelectConnectorForm from './SelectConnectorForm'
import { firebaseCreateRequest } from '../../services/request'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'




const initialValues =
{
  title: '',
  description: '',
  businessType: [],
  location: [],
  industries: [],
  tags: [],
  connectorIds: []
}

interface ContactRequestFormProps {
  step: number
  setStep: (step: number) => void
}

const ContactRequestForm = (props: ContactRequestFormProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request' })

  const validationSchema = useMemo( () => [
    yup.object({
      title: yup
        .string()
        .required(t('requestDescription.requestValidation.titleRequired'))
        .max(120, t('requestDescription.requestValidation.titleMaxLength120')),
      description: yup
        .string()
        .required(t('requestDescription.requestValidation.descriptionRequired'))
        .max(1000, t('requestDescription.requestValidation.descriptionMaxLength1000')),
      businessType: yup.array(),
      location: yup.array(),
      industries: yup.array(),
      tags: yup.array(),
    }),
    yup.object({
      connectorIds: yup.array().min(1,  t('requestDescription.requestValidation.connectorIdsOneRequired'))
    })
  ], [t])

  const { step, setStep } = props
  const navigate = useNavigate()
  const { showErrorMessage, showSuccessMessage } = React.useContext(ShowMessageContext)

  const _submitForm = (values: any, actions: any) => {
    firebaseCreateRequest({
      values: values,
      onSuccess: () => {
        showSuccessMessage(t("requestSentMessage"))
        navigate('/dashboard')
      },
      showErrorMessage: showErrorMessage,
    })
  }

  return (
    <>
      <Formik
        initialValues={
          initialValues
        }
        validationSchema={validationSchema[step]}
        onSubmit={(values, actions) => {
          if (step === 1) {
            _submitForm(values, actions);
          } else {
            setStep(step + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit} id="contact-request" mb={4}>
            {/* { step === 1 && <Box display="flex" justifyContent="space-between">
              <Button onClick={() => setStep(step - 1)} startIcon={<ArrowBack />}>
                {t("selectConnectors.buttonBack")}
              </Button>
              
              <Button
                variant="contained"
                endIcon={undefined}
                type="submit"
                sx={{ ml: 2 }}
              >
                { t("selectConnectors.sendRequestButton")}
              </Button>
            </Box>} */}
            { // @ts-ignore
              step === 0 ? <RequestDescriptionForm formik={formik} /> : <SelectConnectorForm formik={formik} />
            }
            <Box py={1}></Box>
            <Box display="flex" justifyContent="space-between">
              {step === 0 ? (
                <Button onClick={() => navigate(-1)}>
                  {t("requestDescription.buttonCancel")}
                </Button>
              ) :
                (
                  <Button onClick={() => setStep(step - 1)} startIcon={<ArrowBack />}>
                    {t("selectConnectors.buttonBack")}
                  </Button>
                )}
              <Button
                variant="contained"
                endIcon={step === 0 ? <ArrowForward /> : undefined}
                type="submit"
                sx={{ ml: 2 }}
              >
                {step === 0 ? t("requestDescription.buttonFindConnectors") : t("selectConnectors.sendRequestButton")}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  )
}

export default ContactRequestForm