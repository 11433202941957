import React from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { industries, Inudstries } from '../types/dreico.types'
import { CustomAutocompleteProps } from '../types/forms.types'
import { TranslateFunction } from '../types/i18next'
import { useTranslation } from 'react-i18next'

interface IndustriesInputProps extends CustomAutocompleteProps {
  value: Inudstries[]
  error?: boolean
  helperText?: string[] | string | false
}

const IndustriesInput = (props: IndustriesInputProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.requestDescription' })
  return (
    <Autocomplete
      multiple
      id="industries"
      value={props.value || []}
      onChange={props.onChange}
      options={industries}
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((industryName, index) => (
          <Chip
            label={industryName}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField 
          {...params}
          label={t("requestedProfile.requestedIndustries.requestedIndustriesPlaceholder")}
          placeholder=""
          name="industries"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}

export default IndustriesInput