import React, { useEffect, useState } from 'react'
import Imprint from '../../pages/privacy-policy.md'
import ReactMarkdown from 'react-markdown'
import LayoutWithoutMenu from '../../containers/LayoutWithoutMenu'

const PrivacyPolicyScreen = () => {

  const [pageMarkdown, setPageMarkdown] = useState('')

  useEffect(() => {
    fetch(Imprint)
      .then(response => response.text())
      .then(text => {
        setPageMarkdown(text)
      })
  }, [])

  return (
    <LayoutWithoutMenu>
      <ReactMarkdown>
        {pageMarkdown}
      </ReactMarkdown>
    </LayoutWithoutMenu>
  )
}

export default PrivacyPolicyScreen