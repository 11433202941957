import * as colors from '@mui/material/colors';

/**
 * Picks a color based on an input string
 * @param input Input string that determines what color to pick.
 */
 const pickRandomColor = (input: number): string => {
  const colorsObject = {...colors}
  const colorsList = Object.values(colorsObject)
  const randomColorIndex = ((input * 7) + 2)  % colorsList.length
  const randomColor = colorsList[randomColorIndex]
  const shades = Object.values(randomColor)
  const randomShadeIndex = ((input * 7) + 2) % shades.length
  return shades[randomShadeIndex]
}

export default pickRandomColor