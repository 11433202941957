import React, { useContext } from 'react'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import * as yup from 'yup'
import IndustriesInput from '../atoms/IndustriesInput'
import LocationInput from '../atoms/LocationInput'
import TagsInput from '../atoms/TagsInput'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import FormikWithShowingErrors from "./FormikWithShowingErrors";
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../types/i18next'
import { ShowMessageContext } from '../contexts/ShowMessageContext'


interface NetworkFormProps {
  setEditMode: (editMode: boolean) => void
}

const NetworkForm = (props: NetworkFormProps) => {

  const { user } = useContext(UserContext)
  const { showSuccessMessage } = useContext(ShowMessageContext)
  const { setEditMode } = props

  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })

  const validationSchema = yup.object({
    inudstries: yup.array().of(
      yup.string()
    ),
    location: yup.array(),
    tags: yup.array().of(
      yup.object().shape({
        name: yup.string(),
      })
    )
  })

  if (user?._type === 'user' || !user?._type) {
    return null
  }

  return (
    <>
      <FormikWithShowingErrors
        initialValues={{
          industries: user?.networkOntology?.industries || [],
          location: user?.networkOntology?.location || [],
          tags: user?.networkOntology?.tags || [],
        }}

        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          user && await firebaseSetUser({
            values: {
              networkOntology: {
                _type: 'networkOntology',
                businessType: null,
                industries: values.industries,
                location: values.location,
                tags: values.tags,
              },
            },
            actionType: 'updateNetworkData',
            user: user,
            onSuccess: () => {
              setEditMode(false)
              showSuccessMessage(t('form.network.successMessage'))
            },
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.network.locations.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.network.locations.description')}
                </Typography>
                <LocationInput
                  value={formik.values.location}
                  formik={formik}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location as string | string[] | undefined}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.network.industries.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.network.industries.description')}
                </Typography>
                <IndustriesInput
                  value={formik.values.industries}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('industries', newValue)
                  }}
                  error={formik.touched.industries && Boolean(formik.errors.industries)}
                  helperText={formik.touched.industries && formik.errors.industries}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.network.tags.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.network.tags.description')}
                </Typography>
                <TagsInput
                  value={formik.values.tags}
                  formik={formik}
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={formik.touched.tags && formik.errors.tags as string[] | string | undefined}
                />
              </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={() => setEditMode(false)}>{t('form.formCancelButton')}</Button>
              <Box px={1} />
              <Button color="primary" variant="contained" type="submit">
                {t('form.formSaveButton')}
              </Button>
            </Box>
          </Box>
        )}
      </FormikWithShowingErrors>

    </>
  )
}

export default NetworkForm