import React, { useContext, useEffect } from 'react'
import { httpsCallable } from 'firebase/functions';
import { firebaseFunctions } from '../../services/firebase'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../../routes/Routes';
import { ShowMessageContext } from '../../contexts/ShowMessageContext';
import { TranslateFunction } from '../../types/i18next';
import { useTranslation } from 'react-i18next';

const AddContactScreen = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'addContact' })

  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useContext(ShowMessageContext)
  let [searchParams] = useSearchParams()

  useEffect(() => {
    (async () => {
      const inviteId = searchParams.get("inviteId");
      if (!inviteId) {
        showErrorMessage(t('invalidURL'))
        navigate(AppRoutes.DASHBOARD)
      }

      const consumeContactBookInvite = httpsCallable(firebaseFunctions, 'consumeContactBookInvite')
      try {
        await consumeContactBookInvite({
          contactBookInviteId: inviteId
        })
        showSuccessMessage(t('addContactSuccess'))
        navigate(AppRoutes.CONTACT_BOOK)
      } catch (e) {
        showErrorMessage(t('errorTryAgain'))
        navigate(AppRoutes.DASHBOARD)
      }
    })()
  }, [searchParams, navigate, showErrorMessage, showSuccessMessage, t])

  return (
    <div></div>
  )
}

export default AddContactScreen