import React, { useState } from 'react'
import Button from '@mui/material/Button'

interface AsyncButtonProps {
  label: string
  labelOnLoading?: string
  labelDisabled?: string
  disabled?: boolean
  callback?: () => void
  onClick: () => Promise<void>
  variant: "contained" | "outlined" | "text"
}

const AsyncButton = (props: AsyncButtonProps) => {
  const {
    label,
    labelOnLoading,
    labelDisabled,
    disabled,
    callback,
    onClick,
    variant } = props

  const [_disabled, setDisabled] = useState(false)
  const [text, setText] = useState(disabled ? labelDisabled : label)

  const handleClick = async () => {
    labelOnLoading && setText(labelOnLoading)
    await onClick()
    callback && callback()
    setDisabled(true)
    setText(labelDisabled || label)
  }

  return (
    <Button
      variant={variant}
      disabled={disabled || _disabled}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

export default AsyncButton