import { auth, db } from './firebase'
import { doc, setDoc, collection, getDocs, query } from "firebase/firestore"
import { Tag } from "../types/dreico.types"
import {ErrorWithDetails} from "../utility/error-handling/error-handling-utility";

// Note: Tag name (e.g. "Finance") is unique and therefore used as identifier in Firebase.

interface GetAllTagsVars {
  setTags?: (tags: Tag[]) => void
}

export const getAllTags = async (vars: GetAllTagsVars) => {

  const { setTags } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const tags: Tag[] = []

  const q = query(collection(db, "tags"))

  try {
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      tags.push({ name: doc.id } as Tag)
    })
    setTags && setTags(tags)
  } catch (error) {
    console.log(error)
  }
  return tags
}

interface CreateTagVars {
  newTag: Tag
}

export const createTagIfNotExists = async (vars: CreateTagVars) => {
  /***
   * Create a new tag if it doesn't exist yet.
   * If tag already exists, it's not modified.
   */

  const { newTag } = vars

  if (!auth.currentUser) {
    console.log('No user logged in')
    return
  }

  const docRef = doc(db, "tags", newTag.name)

  try {
    await setDoc(docRef, {}, { merge: true })
  }
  catch (error) {
    throw new ErrorWithDetails(error, `creating tag ${newTag.name}`)
  }
}



