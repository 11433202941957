import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyConnectionsSVG } from '../../assets/empty_state_connections.svg'
import { TranslateFunction } from '../../types/i18next'
import EmptyList, { SpecificEmptyListProps } from './EmptyList'

const EmptyConnections = (props: SpecificEmptyListProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections' })

  const { isFiltered=false, ...overrides } = props
  const unfiltered = useMemo( () => ({
    title: t('connectionsEmptyHeadline'),
    text: t('connectionsEmptyText'),
  }), [t])
  const filtered = useMemo(() => ({
    title: t('connectionsEmptyFilterTitle'),
    text: t('connectionsEmptyFilterText'),
  }), [t])
  return <EmptyList
  { ...(isFiltered ? filtered: unfiltered)}
  {...overrides}
    placeHolderSVG={EmptyConnectionsSVG}
  />
}

export default EmptyConnections