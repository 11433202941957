import React from 'react'
import { BusinessOntology, NetworkOntology, RequestOntology } from '../types/dreico.types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { SxProps } from '@mui/material'
import { styles } from '../styles/styles'
import { TranslateFunction } from '../types/i18next'
import { useTranslation } from 'react-i18next'

interface DisplayedOntologyProps {
  title?: string
  sx?: SxProps
  indented?: boolean
  ontology: RequestOntology | NetworkOntology | BusinessOntology
}

const chipStyle = {
  fontWeight: 400,
  bgcolor: '#dedede',
  marginRight: 1,
  marginBottom: 1,
  color: styles.LIGHT_TEXT_PRIMARY,
  "&:hover": {
    bgcolor: '#dedede',
    color: styles.LIGHT_TEXT_PRIMARY,
  }
}

const DisplayedOntology = (props: DisplayedOntologyProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.requestDescription' })
  const { title, ontology, sx, indented } = props

  return (
    <Box maxWidth={800} sx={sx}>
      {title &&
        <Typography variant="h6" pb={3} >
          {title}
        </Typography>
      }
      <Box pl={indented ? 2 : 0}>
        {
          ontology.businessType && ontology.businessType.length > 0 &&
          (<Box pb={3}>
            <Typography variant="subtitle1">
              {t("requestedProfile.requestedBusinessType.title")}
            </Typography>
            {ontology._type === 'requestOntology' ? ontology.businessType.map((businessType, i) => (
              <Typography key={businessType + i} >
                {businessType}
              </Typography>)) :
              <Typography> {ontology.businessType} </Typography>}
          </Box>)
        }
        {
          ontology.location && (
            <Box pb={3}>
              <Typography variant="subtitle1">
                {t("requestedProfile.requestedRegions.title")}
              </Typography>
              <Box pt={1} sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'flex-start' }}>
                {ontology.location.map((l, i) => (
                   <Chip key={l.description + i} sx={chipStyle} label={l.description} />
                ))}
              </Box>
            </Box>
          )
        }
        {
          ontology.industries && (
            <Box pb={3}>
              <Typography variant="subtitle1">
                {t("requestedProfile.requestedIndustries.title")}
              </Typography>
              <Box pt={1}>
                {ontology.industries.map((industry, ind) => (
                  <Chip key={industry + ind} sx={chipStyle} label={industry} />
                ))}
              </Box>
            </Box>
          )
        }
        {ontology.tags && ontology.tags.length > 0 &&
          <Box>
            <Typography variant="subtitle1">
              {t("requestedProfile.requestedTags.title")}
            </Typography>
            <Box pt={1}>
              {ontology.tags.map((tag, i) => (
                <Chip key={tag.name + i} sx={chipStyle} label={tag.name} />
              ))}
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default DisplayedOntology
