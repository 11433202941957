import { BrowserRouter, Route, Routes, } from 'react-router-dom'
import Layout from '../containers/Layout'
import DashboardScreen from '../views/App/DashboardScreen'
import LoginScreen from '../views/LoginScreen'
// import WelcomeScreen from '../views/WelcomeScreen'
import ProfileScreen from '../views/App/PersonalProfileScreen'
import RequireAuth from './RequireAuth'
import { AppRoutes } from './Routes'
import AnfragenScreen from '../views/App/AnfragenScreen'
import VernetzungenScreen from '../views/App/VernetzungenScreen'
import ContactbookScreen from '../views/App/ContactbookScreen'
import SettingsScreen from '../views/App/SettingsScreen'
import ResetPasswordScreen from "../views/ResetPasswordScreen";
import KontaktanfrageScreen from '../views/App/KontaktanfrageScreen'
import VermittelnScreen from '../views/App/VermittelnScreen'
import ConnectorRegistrationScreen from "../views/ConnectorRegistrationScreen";
import UserRegistrationScreen from "../views/UserRegistrationScreen";
import MailActionScreen from '../views/MailActionScreen'
import PrivacyPolicyScreen from '../views/Generic/PrivacyPolicyScreen'
import ImprintScreen from '../views/Generic/ImprintScreen'
import TermsOfUseScreen from '../views/Generic/TermsOfUseScreen'
import AddContactScreen from '../views/App/AddContactScreen'


const AppRouter = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path={AppRoutes.ROOT} element={<LoginScreen />}></Route>
        <Route path={AppRoutes.LOGIN} element={<LoginScreen />}></Route>
        <Route path={AppRoutes.MAIL_ACTION} element={<MailActionScreen />}></Route>
        <Route path={AppRoutes.CONNECTOR_SIGNUP} element={<ConnectorRegistrationScreen />}></Route>
        <Route path={AppRoutes.SIGNUP} element={<UserRegistrationScreen />}></Route>
        <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPasswordScreen />}></Route>
        <Route path={AppRoutes.PRIVACY_POLICY} element={<PrivacyPolicyScreen />}></Route>
        <Route path={AppRoutes.IMPRINT} element={<ImprintScreen />}></Route>
        <Route path={AppRoutes.TERMS_OF_USE} element={<TermsOfUseScreen />}></Route>
        <Route path={AppRoutes.ADD_CONTACT} element={
          <RequireAuth>
            <Layout>
              <AddContactScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.DASHBOARD} element={
          <RequireAuth>
            <Layout>
              <DashboardScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.PROFILE} element={
          <RequireAuth>
            <Layout>
              <ProfileScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.ANFRAGEN} element={
          <RequireAuth>
            <Layout>
              <AnfragenScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.VERNETZUNGEN} element={
          <RequireAuth>
            <Layout>
              <VernetzungenScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.KONTAKTANFRAGE} element={
          <RequireAuth>
            <Layout>
              <KontaktanfrageScreen />
            </Layout>
          </RequireAuth>
        }></Route>

        <Route path={AppRoutes.CONTACT_BOOK} element={
          <RequireAuth>
            <Layout>
              <ContactbookScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.SETTINGS} element={
          <RequireAuth>
            <Layout>
              <SettingsScreen />
            </Layout>
          </RequireAuth>
        }></Route>
        <Route path={AppRoutes.VERMITTELN} element={
          <RequireAuth>
            <Layout>
              <VermittelnScreen />
            </Layout>
          </RequireAuth>
        }>
        </Route>
        <Route path="*" element={<div>404</div>}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter