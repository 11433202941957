import {AuthErrorCodes} from "firebase/auth";
import {FirebaseError} from "@firebase/util";

export class ErrorWithDetails {
    // An error that includes additional details, e.g. which action was executed when the error occurred.
    originalError: any;
    failedAction: string;

    constructor(originalError: any, failedAction: string) {
        this.originalError = originalError;
        this.failedAction = failedAction;
    }
}

export const generateUserFriendlyErrorMessage = (error: any): string => {
    // Log full error to console.
    console.error(error)

    // If a specific user-friendly message generator exists, use it.
    if (error instanceof FirebaseError) {
        return new FirebaseUserFriendlyErrorMessageGenerator().generateMessage(error)
    }
    // Otherwise, use generic user-friendly message generator.
    else {
        return new GenericUserFriendlyErrorMessageGenerator().generateMessage(error)
    }
}

abstract class UserFriendlyErrorMessageGenerator {
    abstract generateMessage: (error: any) => string
}

class GenericUserFriendlyErrorMessageGenerator implements UserFriendlyErrorMessageGenerator {
    generateMessage = (error: any): string => {
        // If details were provided, include them.
        if (error instanceof ErrorWithDetails) {
            return `While ${error.failedAction} an internal error occurred. Please contact our support team.` + GenericUserFriendlyErrorMessageGenerator.generateTechnicalDetails(error.originalError)
        }
        // Otherwise, generate generic error message.
        else {
            return "An internal error occurred, please contact our support team." + GenericUserFriendlyErrorMessageGenerator.generateTechnicalDetails(error)
        }
    }

    static generateTechnicalDetails = (error: any): string => {
        const prefix = " Technical details: "
        if (error instanceof Error) {
            return `${prefix}${error.message}`
        }
        if (typeof error === 'string') {
            return `${prefix}${error}`
        }
        return '';
    }
}

class FirebaseUserFriendlyErrorMessageGenerator implements UserFriendlyErrorMessageGenerator{
    generateMessage(error: FirebaseError): string {
        if (error.code === AuthErrorCodes.INVALID_PASSWORD
            || error.code === AuthErrorCodes.USER_DELETED) {
            return "There's no user with this e-mail/password combination."
        }
        // TODO: Add more user friendly messages for the available AuthErrorCodes.
        // Fallback to generic message.
        else {
            return new GenericUserFriendlyErrorMessageGenerator().generateMessage(error)
        }
    }
}
