import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import FormikWithShowingErrors from "../forms/FormikWithShowingErrors";
import { ReactComponent as LogoDark } from '../assets/logo-blue.svg';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    newPassword: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], "Passwords don't match!") // todo
      .required('Password is required'),
});

interface ResetPasswordFormProps {
  doReset: (password: string, email: string) => void
}

const ResetPasswordForm = ({doReset}:ResetPasswordFormProps) => {
    return (
        <Box
            sx={{
                m: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box width={'18rem'} height={'12rem'}>
                <LogoDark width={'100%'} height={'100%'} />
            </Box>
            <Typography component="h1" variant="h5" mt={2}>
                Legen Sie ein neues Passwort fest.
            </Typography>
            <FormikWithShowingErrors
                initialValues={{
                    email: '',
                    newPassword: '',
                    confirmPassword:''
                }}
                validationSchema={validationSchema}
                onSubmit={({email, newPassword}) => doReset(newPassword, email)}>
                {formik => (
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="E-mail"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            id="newPassword"
                            name="newPassword"
                            label="Neues Passwort"
                            type="password"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Passwort bestätigen"
                            type="password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            margin="normal"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {formik.isSubmitting ? <CircularProgress /> : "Neues Passwort setzen"}
                        </Button>
                    </Box>
                )}
            </FormikWithShowingErrors>
        </Box>
    );
};

export default ResetPasswordForm