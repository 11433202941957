// React and router
import { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from 'react-router-dom'
import { AppRoutes } from "../routes/Routes"

// firebase and related
import { applyActionCode, confirmPasswordReset, getAuth, verifyPasswordResetCode } from "firebase/auth";
// MUI Components

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { initializeApp } from "firebase/app"
import ResetPasswordForm from "../forms/ResetPasswordForm"
import { useTranslation } from "react-i18next";
import { TranslateFunction } from "../types/i18next";

enum ActionStatus {
  PENDING = 'pending',
  FULLFILLED = 'fullfilled',
  DENIED = 'denied'
}
enum ActionType {
  RECOVER_MAIL = 'recoverEmail',
  RESET_PASSWORD = 'resetPassword',
  VERIFY_MAIL = 'verifyEmail'
}

interface BaseMailActionProps {
  navigateToLogin: () => void,
  actionStatus: ActionStatus
}

interface MailActionProps extends BaseMailActionProps {
  doAction: () => void
}

interface ResetPasswordProps extends BaseMailActionProps {
  doAction: (password: string, email: string) => void
}

const ActionDenied = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'mailAction' })
  return <Typography variant="body1">
    {t('confirmationLinkInvalid.description')}
    <br />
    <br />
    {t('confirmationLinkInvalid.advise')}
  </Typography>
}

const VerifyMail = ({ doAction, actionStatus, navigateToLogin }: MailActionProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'mailAction' })
  return (<Box>
    <Typography component="h1" variant="h5" pb={3}> {t('verifyMail.title')} </Typography>
    {
      actionStatus === ActionStatus.PENDING
        ? <>
          <Typography variant="body1" pb={2}>
            {t('verifyMail.description')}
          </Typography>
          <Button variant="outlined" onClick={doAction}>{t('verifyMail.buttonLabel')}</Button>
        </>
        : actionStatus === ActionStatus.DENIED
          ? <ActionDenied />
          : <>
            <Typography variant="body1" pb={2}> {t('verifyMail.verifySuccess')} </Typography>
            <Button variant="outlined" onClick={navigateToLogin}> {t('linkButtonToLogin')} </Button>
          </>
    }
  </Box>)
}

const ResetPassword = ({ doAction, actionStatus, navigateToLogin }: ResetPasswordProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'mailAction' })
  return (<>
    <Typography component="h1" variant="h5" pb={3}>
      {t('resetPassword.title')}
    </Typography>
    {
      actionStatus === ActionStatus.PENDING
        ? <ResetPasswordForm doReset={doAction} />
        : actionStatus === ActionStatus.DENIED
          ? <ActionDenied />
          : <>
            <div>
              <Typography variant="body1">
                {t('resetPassword.passwordChangeSuccess')}
              </Typography>
              <Button onClick={navigateToLogin}>{t('linkButtonToLogin')}</Button>
            </div>
          </>
    }
  </>)
}
const RecoverMail = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'mailAction' })

  return (<>
    <Typography component="h1" variant="h5" pb={3}> {t('recoverMail.title')} </Typography>
    <Typography variant="body1" > {t('recoverMail.recoverNotImplemented')}</Typography>
  </>)
}


const MailActionScreen = () => {
  const navigate = useNavigate()
  const queryParamsObj = useSearchParams()[0]
  const [actionStatus, setActionStatus] = useState(ActionStatus.PENDING)
  const actionCode = queryParamsObj.get('oobCode') || ''
  const mode = queryParamsObj.get('mode') || ''
  const apiKey = queryParamsObj.get('apiKey') || ''
  const modes: string[] = Object.values(ActionType)
  if (
    [actionCode, apiKey, mode, modes.includes(mode)].some((e) => !e)
  ) {
    return <>unauthorized</>
  }
  const config = { apiKey }
  const app = initializeApp(config, "mail_action")
  const auth = getAuth(app);
  const doVerifyMail = async () => {
    try {
      await applyActionCode(auth, actionCode)
      setActionStatus(ActionStatus.FULLFILLED)
    }
    catch (error) {
      setActionStatus(ActionStatus.DENIED)
    }
  }
  const doPasswordReset = async (password: string, email: string) => {
    try {
      const resp_mail = await verifyPasswordResetCode(auth, actionCode)
      if (resp_mail.toLowerCase() !== email.toLowerCase()) {
        throw new Error("Wrong mail address")
      }
      await confirmPasswordReset(auth, actionCode, password)
      setActionStatus(ActionStatus.FULLFILLED)

    } catch (error) {
      setActionStatus(ActionStatus.DENIED)
    }
  }

  return (
    <Box
      sx={{
        m: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          m: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          maxWidth: 650
        }}
      >
        {
          mode === ActionType.VERIFY_MAIL
            ? <VerifyMail doAction={doVerifyMail} actionStatus={actionStatus} navigateToLogin={() => navigate(AppRoutes.LOGIN)} />
            : mode === ActionType.RESET_PASSWORD
              ? <ResetPassword doAction={doPasswordReset} actionStatus={actionStatus} navigateToLogin={() => navigate(AppRoutes.LOGIN)} />
              : <RecoverMail />
        }
      </Box>
    </Box>
  );
};

export default MailActionScreen;