import React, { useContext, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import { theme } from '../styles/theme'
import {  FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from '@mui/material'
import * as yup from 'yup'
import FormikWithShowingErrors from '../forms/FormikWithShowingErrors'
import { firebaseAddContact, firebaseCreateInvite } from '../services/invite'
import { ShowMessageContext } from '../contexts/ShowMessageContext'
import { ContactRequest } from "../types/dreico.types";
import { UserContext } from '../contexts/UserContext'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../types/i18next'
import AsyncSubmitButton from '../atoms/AsyncSubmitButton'


import { firebaseSetUser } from '../services/user'

const boxStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '1rem',
  minWidth: '300px',
  maxWidth: '450px',
}

interface InviteModalProps {
  open: boolean
  handleClose: () => void
  prompt: string
  request?: ContactRequest
}

const InviteModal = (props: InviteModalProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connect.selectContacts' })

  const { open, handleClose } = props
  const { user } = useContext(UserContext)
  const { showSuccessMessage, showErrorMessage, showWarningMessage } = useContext(ShowMessageContext)
  const [modalState, setModalState] = useState(0)


  /**
 * Modal that allows users to invite other users.
 * If a request is set in props, the invited user shall be added to that request.
 */

  const validationSchema = useMemo(() => {
    let validationSchema = yup.object({
      email: yup
        .string()
        .email(t('inviteConnection.validationMessages.validEmail'))
        .required(t('inviteConnection.validationMessages.validEmail')),
    })
    if (props.request != null) {
      validationSchema = validationSchema.concat(yup.object({
        message: yup.string().required(t('inviteConnection.validationMessages.messageRequired')),
      }))
    }
    if (user?._type === 'admin') {
      validationSchema = validationSchema.concat(yup.object({
        userType: yup.string().required(t('inviteConnection.validationMessages.userTypeRequired')),
      }))
    }
    return validationSchema
  }, [props, user?._type, t])

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <FormikWithShowingErrors
        initialValues={{ email: '', message: props.request !== undefined ? '' : undefined, userType: undefined }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (modalState === 0) {
            const res = await firebaseCreateInvite({
              email: values.email,
              message: values?.message,
              request: props?.request,
              userType: values?.userType
            })
            if (res?.success) {
              props?.request ? showSuccessMessage(t('inviteConnection.successMessageRequest')) : showSuccessMessage(t('inviteConnection.successMessage'))
              handleClose()
              if (props?.request) {
                user && await firebaseSetUser({
                  values: {
                    requestId: props?.request?._id,
                    view: 'processed',
                  },
                  actionType: 'updateRequestView',
                  user: user,
                })
              }
            } else if (res?.error) {
              showErrorMessage(res?.message)
              handleClose()
            } else {
              showWarningMessage(res?.message as string)
              setModalState(1)
            }
          } else if (modalState === 1) {
            const res = await firebaseAddContact({
              email: values.email,
            })
            if (res?.success) {
              showSuccessMessage(t('inviteConnection.successMessage'))
            } else if (res?.error) {
              showErrorMessage(res?.message)
            }

            setModalState(0)
            handleClose()
          }
        }}
      >
        {(formik) => (
          <Box bgcolor={theme.palette.background.default} sx={boxStyle} px={2} py={2}>
            {modalState === 0 ? (<>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">{t('inviteConnection.title') }</Typography>
                <IconButton>
                  <Close onClick={() => { handleClose() }} />
                </IconButton>
              </Box>
              <Typography variant="body1" pt={2}>
                {props.prompt}
              </Typography>

              <Box component="form" display="flex" flexDirection="column" noValidate onSubmit={formik.handleSubmit}>
                <Typography variant="h6" pt={2} pb={1}>
                  {t('inviteConnection.email')}
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={t('inviteConnection.emailPlaceholder')}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {props.request && (
                  <Box>
                    <Typography variant="h6" pt={2} pb={1}>
                    {t('inviteConnection.message')}
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {t('inviteConnection.messageDescription')}
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      id="message"
                      name="message"
                      label={t('inviteConnection.messagePlaceholder')}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      helperText={formik.touched.message && formik.errors.message}
                    />
                  </Box>
                )}
                {user?._type === 'admin' && (
                  <Box>
                    <Typography variant="h6" pt={2} pb={1}>
                      {t('inviteConnection.selectUserType.description')}
                    </Typography>
                    <RadioGroup
                      name="userType"
                      value={formik.values.userType}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel value="connector" control={<Radio />} label={t('inviteConnection.selectUserType.connectorLabel')} />
                      <FormControlLabel value="user" control={<Radio />} label={t('inviteConnection.selectUserType.userLabel')}/>
                      {Boolean(formik.errors.userType) && <FormHelperText error={true} sx={{ ml: '14px' }}>
                        {formik.errors.userType}
                      </FormHelperText>}
                    </RadioGroup>
                  </Box>
                )}
                <Box display="flex" justifyContent="flex-end" pt={2}>
                  <AsyncSubmitButton 
                    isLoading={formik.isSubmitting}
                    label={t('inviteConnection.inviteButtonLabel')}
                    variant="contained"
                    buttonProps={{
                      sx: { mt: 3, mb: 2 },
                      fullWidth: true,
                    }}
                  />
                </Box>
              </Box>
            </>) : (<>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6"> {t('inviteConnection.contactAlreadyExists')} </Typography>
                <IconButton>
                  <Close onClick={() => {
                    handleClose()
                    setModalState(0)
                  }} />
                </IconButton>
              </Box>
              <Box component="form" display="flex" flexDirection="column" noValidate onSubmit={formik.handleSubmit}>
                <Typography variant="body1" pt={2} pb={2}>
                  {t('inviteConnection.inviteDescriptionLong')}
                </Typography>
                <Box display="flex" justifyContent="flex-end" pt={2}>
                  <AsyncSubmitButton 
                    isLoading={formik.isSubmitting}
                    label={t('inviteConnection.addToContactBookButton')}
                    variant="contained"
                  />
                </Box>
              </Box>
            </>)}
          </Box>
        )}
      </FormikWithShowingErrors>
    </Modal>
  )
}

export default InviteModal