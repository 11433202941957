import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmptyRequestsSVG } from '../../assets/empty_state_requests.svg'
import { TranslateFunction } from '../../types/i18next'
import EmptyList, { SpecificEmptyListProps } from './EmptyList'

const EmptyRequests = (props: SpecificEmptyListProps) => {

  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'requests' })
  const { isFiltered=false, ...overrides } = props

  const unfiltered = useMemo( () => ({
    title: t('requestsEmptyHeadline'),
    text: t('requestsEmptyText'),
  }), [t])
  const filtered = useMemo(() => ({
    title: t('requestsEmptyFilterTitle'),
    text: t('requestsBookEmptyFilterText'),
  }), [t])

  return <EmptyList
    { ...(isFiltered ? filtered: unfiltered)}
    {...overrides}
    placeHolderSVG={EmptyRequestsSVG}
  />
}

export default EmptyRequests