const firebaseConfig = {
    apiKey: "AIzaSyCsItej8irM18eiteNmxXDHtBU9mI1mJlM",
    authDomain: "dreico-corvus.firebaseapp.com",
    projectId: "dreico-corvus",
    storageBucket: "dreico-corvus.appspot.com",
    messagingSenderId: "619062417262",
    appId: "1:619062417262:web:8e2b5da7b712486e14d37f",
    measurementId: "G-BPPYDEF4RD"
}

export default firebaseConfig