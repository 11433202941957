import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import DisplayedOntology from '../DisplayedOntology'
import NameToModal from './NameToModal'
import AsyncButton from '../../atoms/AsyncButton'
import { firebaseSetRequestStatus } from '../../services/request'
import Typography from '@mui/material/Typography'
import { ContactRequest } from '../../types/dreico.types'
import { Timestamp } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import { SxProps } from '@mui/material'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'


interface RequestContentProps {
  request?: ContactRequest
  isModal?: boolean
  handleClose?: () => void
  sx?: SxProps
}


const RequestContent = (props: RequestContentProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'requests' })

  const { request, isModal, handleClose, sx } = props
  const [expanded, setExpanded] = useState(false)

  const { showSuccessMessage } = useContext(ShowMessageContext)

  return (
    request ? (
      <Box sx={sx}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {request.title}
          </Typography>
          {!isModal && <IconButton aria-label="expand" onClick={() => { isModal ? handleClose && handleClose() : setExpanded(!expanded) }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>}
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <Chip
            label={request.status === 'closed' ? t("requestDetails.inactive") : t("requestDetails.active")}
            color={request.status === 'closed' ? 'success' : 'primary'}
            sx={{ height: '20px' }}
          />
          <Typography variant="body2" sx={{ pl: 1 }}>
            {
              typeof request.createdAt !== 'number' ?
                new Timestamp(
                  request.createdAt.seconds, request.createdAt.nanoseconds).toDate().toLocaleDateString()
                : (new Date(request.createdAt)).toLocaleDateString()
            }
          </Typography>
        </Box>

        {(expanded || isModal) && (
          <>
            {request.connectorIds ? <Box pt={3} pb={2}>
              <Typography variant="h6">
                {t("requestDetails.selectedConnectors")}
              </Typography>
              {request.connectorIds?.map((connectorId) => (
                <NameToModal id={connectorId} />
              ))}
            </Box> : <Box my={3}></Box>}

            <Box pt={1} pb={2}>
              <Typography variant="h6">
                {t("requestDetails.description")}
              </Typography>
              <Typography variant="body1">
                {request.description}
              </Typography>
            </Box>
            {request.connectorIds  && <Box pt={1} pb={2}>
              <Typography variant="body2" mb={2}>
                {t("requestDetails.descriptionEndRequest")}
              </Typography>
              <Box display="flex" justifyContent={["flex-end", "flex-end", "flex-start"]}>
                <AsyncButton
                  label={t("requestDetails.endRequestButton.label")}
                  labelOnLoading={t("requestDetails.endRequestButton.labelOnLoading")}
                  labelDisabled={t("requestDetails.endRequestButton.labelFinished")}
                  onClick={() => firebaseSetRequestStatus(request._id, 'closed', () => {showSuccessMessage(t("requestDetails.endRequestButton.successMessage"))})}
                  variant="contained"
                  disabled={request.status === 'closed'} />
              </Box>
            </Box> }
            <DisplayedOntology ontology={request.requestOntology} title={t("requestDetails.searchedBusinessProfile")} />
          </>
        )}
      </Box>
    ) : null
  )
}

export default RequestContent