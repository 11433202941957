import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import dreicoDe from './assets/labels/dreico-de';
import dreicoDe from './locales/dreico-de.json'

export const defaultNS = 'menu'
export const resources = {
  de: {
    dreicoDe,
  }
}

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'de',
    fallbackLng: 'de',
    debug: true,
    ns: ['dreicoDe'],
    defaultNS,
    resources,

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
  });


export default i18n;