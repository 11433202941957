import React, { useContext, useMemo } from 'react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { FirebaseLogin } from '../services/authentication';
import { UserContext } from '../contexts/UserContext';
import FormikWithShowingErrors from "../forms/FormikWithShowingErrors";
import { ShowMessageContext } from '../contexts/ShowMessageContext';
import { ReactComponent as LogoDark } from '../assets/logo-blue.svg';
import { TranslateFunction } from '../types/i18next';
import { useTranslation } from 'react-i18next';



const LoginForm = () => {

    const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'login' })
    const validationSchema = useMemo( () => yup.object({
        email: yup
            .string()
            .email(t('emailValid'))
            .required(t('emailRequired')),
        password: yup
            .string()
            .min(8, t('passwordLen'))
            .required(t('passwordRequired')),
    }), [t])

    const navigate = useNavigate();
    const location = useLocation();
    const { setFirebaseUser } = useContext(UserContext);
    const { showErrorMessage } = useContext(ShowMessageContext)

    // once login succeeded:
    // if we have a from location in our Navigation string, redirect users there
    // if not, redirect them to the dashboard
    const redirectTo = useMemo<string>(() => location.state?.from?.pathname || "/dashboard", [location])

    return (
        <Box
            sx={{
                m: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box width={'18rem'} height={'12rem'}>
                <LogoDark width={'100%'} height={'100%'} />
            </Box>
            <Typography component="h1" variant="h5" mt={2}>
                {t('doLogin')}
            </Typography>
            <FormikWithShowingErrors
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikHelpers) => {
                    await FirebaseLogin({
                        email: values.email,
                        password: values.password,
                        setFirebaseUser: setFirebaseUser,
                        onSuccess: () => navigate(redirectTo),
                        showErrorMessage: showErrorMessage
                    })
                }}>
                {formik => (
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label={t('emailLabel')}
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            id="password"
                            name="password"
                            label={t('passwordLabel')}
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            margin="normal"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {formik.isSubmitting ? <CircularProgress /> : "Log In"}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/reset-password" variant="body2">
                                    {t('passwordForgotten')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </FormikWithShowingErrors>
        </Box>
    );
};

export default LoginForm;