import React from 'react';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@mui/material'

interface CustomSeachBoxProps {
  setSearchValue: (value: string) => void
}

const CustomSearchBox = (props: CustomSeachBoxProps) => {
  const { query, refine } = useSearchBox();
  const { setSearchValue } = props

  return (
    <Box width="100%" mb={3} display="flex">
      <FormControl sx={{ width: '100%', marginLeft: 'auto' }}>
        <TextField
          value={query}
          onChange={(event) => {
            setSearchValue(event.target.value)
            refine(event.target.value)
          }}
          label="Suche"
          fullWidth
        >
        </TextField>
      </FormControl>
    </Box>
  );
}

export default CustomSearchBox