import React, { useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { UserContext } from '../../contexts/UserContext'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const NetworkCards = () => {

  const { user } = useContext(UserContext)
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile.form.network' })

  if (user?._type === 'user' || !user?._type) {
    return <></>
  }

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('locations.title')}
          </Typography>
          <Typography variant="body1">
            {user?.networkOntology?.location.map((loc) => (
              <Chip
                label={loc.description}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('industries.title')}
          </Typography>
          <Typography variant="body1">
            {user?.networkOntology?.industries && user?.networkOntology.industries.map((ind) => (
              <Chip
                key={ind}
                label={ind}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={3}>
            {t('tags.title')}
          </Typography>
          <Typography variant="body1">
            {user?.networkOntology?.tags && user?.networkOntology.tags.map((tag) => (
              <Chip
                key={tag.name}
                label={tag.name}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default NetworkCards