import React from 'react';
import './App.css';
import AppRouter from './routes/AppRouter';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { theme } from './styles/theme';
import { UserContextProvider } from './contexts/UserContext';
import { SnackbarProvider } from "notistack";
import { ShowMessageContextProvider } from "./contexts/ShowMessageContext";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
  return (
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: "center", vertical: "top" }}>
            <ShowMessageContextProvider>
              <AppRouter />
            </ShowMessageContextProvider>
          </SnackbarProvider>
        </I18nextProvider>
      </ThemeProvider>
    </UserContextProvider>

  );
}

export default App;
