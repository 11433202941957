import {Formik, FormikConfig, FormikHelpers, FormikValues} from "formik";
import {useCallback, useContext} from "react";
import {ShowMessageContext} from "../contexts/ShowMessageContext";

const FormikWithShowingErrors = <Values extends FormikValues = FormikValues, ExtraProps = {}>(props: FormikConfig<Values> & ExtraProps): JSX.Element => {
    // Wrapper for <Formik> that catches errors during onSubmit and displays them to users.

    const { showErrorMessage } = useContext(ShowMessageContext)

    // Display error message if error occurs during onSubmit.
    const onSubmitWithDisplayingErrors = useCallback(async (values: Values, formikHelpers: FormikHelpers<Values>) => {
        try {
            await props.onSubmit(values, formikHelpers)
        }
        catch (error) {
            showErrorMessage(error)
        }
    }, [props, showErrorMessage])


    return (
        <Formik {...props} onSubmit={onSubmitWithDisplayingErrors}>
        </Formik>
    )
}

export default FormikWithShowingErrors