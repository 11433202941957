import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { Avatar, CircularProgress } from '@mui/material';
import {FirebaseResetPassword} from "../services/authentication";
import {useNavigate} from "react-router";
import FormikWithShowingErrors from "../forms/FormikWithShowingErrors";
import {useCallback, useContext, useMemo} from "react";
import {ShowMessageContext} from "../contexts/ShowMessageContext";
import {FirebaseError} from "@firebase/util";
import { TranslateFunction } from '../types/i18next';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm = () => {
    const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'resetPassword' })

    const validationSchema = useMemo( () => yup.object({
        email: yup
            .string()
            .email(t('emailValid'))
            .required(t('emailRequired')),
    }), [t]);
    const navigate = useNavigate();
    const {showSuccessMessage, showErrorMessage} = useContext(ShowMessageContext)

    const navigateToLogin = useCallback(() => {
        navigate(("/login"))
        showSuccessMessage(t('resetSuccess'))
    }, [navigate, showSuccessMessage, t])

    return (
        <Box
            sx={{
                m: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t('title')}
            </Typography>
            <FormikWithShowingErrors
                initialValues={{
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    try {
                        await FirebaseResetPassword({
                            email: values.email,
                            onSuccess: () => {
                                navigateToLogin()
                            },
                            showErrorMessage
                        })
                    }
                    catch (error) {
                        // Don't leak information about whether a user exists.
                        if (error instanceof FirebaseError) {
                            navigateToLogin()
                        }
                        else
                        {
                            throw error
                        }
                    }
                }}>
                {formik => (
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label={t('emailLabel')}
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            margin="normal"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {formik.isSubmitting ? <CircularProgress /> : t('resetButtonLabel')}
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link href="/signup" variant="body2">
                                    {t('linkToSignUp')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </FormikWithShowingErrors>
        </Box>
    );
};

export default ResetPasswordForm;