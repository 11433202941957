import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Fab from '@mui/material/Fab'
import { AppRoutes } from '../../routes/Routes'
import AddIcon from '@mui/icons-material/Add'
import ListOfRequests from '../../components/Requests/ListOfRequests'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const AnfragenScreen = () => {
  
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'requests' })

  return (
    <Box>
      <Typography variant="h4" pb={4}>
        {
          t('title')
        }
      </Typography>
      <Box width={'100%'} display="flex" justifyContent="flex-end" pb={4}>
        <Link to={AppRoutes.KONTAKTANFRAGE}>
          <Fab variant="extended" color="primary">
            <AddIcon sx={{ pr: 1 }} />
            { t("buttonStartRequest") }
          </Fab>
        </Link>
      </Box>
      <ListOfRequests />
    </Box>
  )
}

export default AnfragenScreen