import React from 'react'
import { FormikProps } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormGroup from '@mui/material/FormGroup'
import ContactSearch from '../../components/ContactSearch'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'

interface SelectConnectorFormProps {
  formik: FormikProps<any>
}

const SelectConnectorForm = (props: SelectConnectorFormProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.selectConnectors' })

  const { formik } = props

  return (
    <Box>
      <Typography variant="h6" pb={2}>
        {t('headline')}
      </Typography>
      <Typography variant="body2" mb={4}>
        {t('selectConnectorsDescription')}
      </Typography>
      <FormGroup>
        <ContactSearch filter="connector" formik={formik} isContactRequest={true} />
      </FormGroup>
    </Box>
  )
}

export default SelectConnectorForm