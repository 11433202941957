import { Box, Typography, Link as MuiLink } from '@mui/material'
import LabeledSwitch from '../../atoms/LabeledSwitch'
import { Link as RRDLink } from 'react-router-dom'
import ChangePasswordForm from '../../forms/ChangePasswordForm'
import { AppRoutes } from '../../routes/Routes'
import { firebaseSetUser } from '../../services/user'
import { useContext } from 'react'
import { ShowMessageContext } from '../../contexts/ShowMessageContext'
import { auth } from '../../services/firebase'
import { UserContext } from '../../contexts/UserContext'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'

const SettingsScreen = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'settings' })

  const deleteEmailAddress = 'info@dreico.net'
  const { showSuccessMessage, showErrorMessage } = useContext(ShowMessageContext)
  const { currentUser } = auth
  const { user } = useContext(UserContext)

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          m: [0, '2 0 0 0'],
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '600px',
        }}
      >
        <Box>
          <Typography variant="h4" pb={4}>
            {t('title')}
          </Typography>
        </Box>
        <Typography variant="h5" pb={2}>
          {t('changePassword')}
        </Typography>
        <ChangePasswordForm />
        <Typography variant="h5" pb={2}>
          {t('deleteUserAccount.title')}

        </Typography>
        <Typography variant="body2" pb={1}>
          {t('deleteUserAccount.description')}
        </Typography>
        <Typography variant="body2" pb={3}>
          <MuiLink href={`mailto:${deleteEmailAddress}`}>{deleteEmailAddress}</MuiLink>
        </Typography>
        <Typography variant="h5" pb={2}>
          {t('notifications.title')}
        </Typography>
        <Typography variant="body2" pb={2}>
          {t('notifications.description')}
        </Typography>
        <LabeledSwitch
          boxStyles={{ paddingBottom: '24px' }}
          label={t('notifications.switchLabel')}
          checked={typeof user?.emailSettings?.getEmailNotifications == 'undefined' || user?.emailSettings?.getEmailNotifications}
          onChange={async (e) => {
            // default to true if no mailsettings are present
            if (!user || !currentUser) {
              return
            }
            const getEmailNotifications = typeof user?.emailSettings?.getEmailNotifications === "undefined" || user?.emailSettings?.getEmailNotifications
            const getNotificationsInFuture = e.target.checked
            if (getEmailNotifications !== getNotificationsInFuture) {
              try {
                currentUser && await firebaseSetUser({
                  values: { emailSettings: { getEmailNotifications: getNotificationsInFuture } },
                  actionType: 'updateEmailSettings',
                  user,
                  onSuccess: () => showSuccessMessage(t('notifications.notificationChangeSuccess'))
                })
              }
              catch (error) {
                showErrorMessage(t('notifications.notificationChangeNoSuccess'))
              }
            }
          }}
        />
        <Typography variant="h6" pb={2}>
          {t('importantLinks.title')}
        </Typography>
        <Box>
          <RRDLink to={AppRoutes.TERMS_OF_USE}>{t('importantLinks.termsOfUse')}</RRDLink> <br />
          <RRDLink to={AppRoutes.PRIVACY_POLICY}>{t('importantLinks.privacyPolicy')}</RRDLink> <br />
          <RRDLink to={AppRoutes.IMPRINT}>{t('importantLinks.imprint')}</RRDLink> <br />
        </Box>
      </Box>
    </Box>
  )
}

export default SettingsScreen