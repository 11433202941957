import { Connection, User, Connector, Admin } from '../../types/dreico.types'

interface Props {
  groupedConnections: { [key: string]: Connection[] }
  roleFilter: string
  evaluationFilter: string
  user?: User | Connector | Admin
}

export const returnsResults = (props: Props) => {
  
  const { groupedConnections, roleFilter, evaluationFilter, user } = props

  const resultsExit = Object.keys(groupedConnections).some((key) => {
    const connections = groupedConnections[key]
    const userIsRequester = connections[0].requesterId === user?._id

    console.log(userIsRequester)

    const roleMatch = roleFilter === 'all' ||  (userIsRequester && roleFilter === 'requester') || (!userIsRequester && roleFilter === 'connectedUser')

    const evaluationMatch = connections.some((connection) => {
      return user?.connections?.find && user?.connections?.find(personalConnections => personalConnections.id === connection._id)?.evaluation
        === evaluationFilter
    })

    return roleMatch && evaluationMatch
  })

  return resultsExit 
}