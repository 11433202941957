import React from 'react'
import { Admin, Connector, User } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import ArrowBack from '@mui/icons-material/ArrowBack'
import UserInfo from './UserInfo'
import DisplayedOntology from '../DisplayedOntology'
import Modal from '@mui/material/Modal'
import { theme } from '../../styles/theme'
import ContactDataComponent from './ContactDataComponent'

const boxStyles = {
  backgroundColor: theme.palette.background.default,
  width: '100%',
  minHeight: '100%',
}

interface ContactModalProps {
  open: boolean
  handleClose: () => void
  contact: User | Connector | Admin
  networkFirst?: boolean
}

const ContactModal = (props: ContactModalProps) => {
  const { open, contact, handleClose, networkFirst } = props

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={boxStyles}>
        <Box display="flex" justifyContent="space-between" sx={{ pt: 2, px: 1 }}>
          <IconButton onClick={handleClose}>
            <ArrowBack />
          </IconButton>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <UserInfo contact={props.contact} sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', py: 4 }} />
        <ContactDataComponent fetch={open} contactId={contact._id} />
        <Box display="flex" flexDirection={networkFirst ? 'column-reverse' : 'column'}>
          <Box px={2} pb={4}>
            {contact.business?.businessOntology &&
              <DisplayedOntology
                ontology={contact.business?.businessOntology}
                title={"Businessprofil"}
                indented={true}
                sx={{ m: ['', '', 'auto'] }}
              />}
          </Box>
          <Box px={2} pb={4}>
            {contact._type !== 'user' && contact.networkOntology &&
              <DisplayedOntology
                ontology={contact.networkOntology}
                title={"Netzwerkprofil"}
                indented={true}
                sx={{ m: ['', '', 'auto'] }}
              />}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ContactModal
