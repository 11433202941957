import React, {createContext, useCallback} from 'react'
import {useSnackbar} from "notistack";
import {generateUserFriendlyErrorMessage} from "../utility/error-handling/error-handling-utility";

export interface UserContextType {
    showErrorMessage: (error: any, userFriendlyMessage?: string) => void
    showSuccessMessage: (message: string) => void,
    showWarningMessage: (message: string) => void,
    showInfoMessage: (message: string) => void
}

const contextDefaultValues: UserContextType = {
    // Default values are no-ops.
    showErrorMessage: (error, userFriendlyMessage) => { },
    showSuccessMessage: (message) => { },
    showWarningMessage: (message) => { },
    showInfoMessage: (message) => { }
}

export const ShowMessageContext = createContext(
    contextDefaultValues
)

export const ShowMessageContextProvider = ({children}: any) => {
    const {enqueueSnackbar} = useSnackbar()


    const showErrorMessage = useCallback((error: any): any => {
        const userFriendlyMessage = generateUserFriendlyErrorMessage(error)
        enqueueSnackbar(userFriendlyMessage, {variant: "error"})
    }, [enqueueSnackbar])

    const showSuccessMessage = useCallback((message: string) => {
        enqueueSnackbar(message, {variant: "success"})
    }, [enqueueSnackbar])

    const showWarningMessage = useCallback((message: string) => {
        enqueueSnackbar(message, {variant: "warning"})
    }, [enqueueSnackbar])

    const showInfoMessage = useCallback((message: string) => {
        enqueueSnackbar(message, {variant: "info"})
    }, [enqueueSnackbar])

    return (
        <ShowMessageContext.Provider value={{
            showErrorMessage, showSuccessMessage, showWarningMessage, showInfoMessage
        }}>
            {children}
        </ShowMessageContext.Provider>
    )
}

