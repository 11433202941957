import React from 'react'
import { Checkbox, Divider, FormControlLabel } from '@mui/material'
import { useHits, UseHitsProps } from 'react-instantsearch-hooks-web'
import { Connector } from '../../types/dreico.types'
import ContactCard from '../ContactCard'
import generateRecommendation from '../../algorithm'

interface CustomRequestHitProps extends UseHitsProps {
  formik: any
  searchValue?: string
}

const CustomRequestHit = (props: CustomRequestHitProps) => {

  const { formik, searchValue } = props
  const { hits } = useHits(props)

  return (
    <>
      {!(typeof searchValue === 'string' && searchValue?.length === 0) ? //@ts-ignore
        hits.map((hit: Connector) => (
          <>
            <ContactCard contactProp={hit} contactRef={hit._id} sx={{ py: 4 }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.connectorIds.includes(hit._id)}
                    onChange={formik.handleChange}
                    name="connectorIds"
                    value={hit._id}
                  />
                }
                label={''}
              />
            </ContactCard>
            <Divider />
          </>
        )) :
        generateRecommendation({
          //@ts-ignore
          connectors: hits,
          requestOntology: formik.values
        }).map((hit: Connector) => (
          <>
            <ContactCard contactProp={hit} contactRef={hit._id} sx={{ py: 4 }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.connectorIds.includes(hit._id)}
                    onChange={formik.handleChange}
                    name="connectorIds"
                    value={hit._id}
                  />
                }
                label={''}
              />
            </ContactCard>
            <Divider />
          </>
        ))}
    </>
  )
}

export default CustomRequestHit