import React from 'react'
import { Box, ModalProps, Modal as MuiModal } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { theme } from '../styles/theme'

const boxStyles = {
  backgroundColor: theme.palette.background.default,
  width: '100%',
  minHeight: '100%',
}

const Modal = (props: ModalProps) => {

  const { onClose, open, children } = props

  return (
    <MuiModal open={open} onClose={onClose}>
      <Box sx={boxStyles}>
        {props?.onClose && (
          <Box display="flex" justifyContent="space-between" sx={{ pt: 2, px: 1 }}>
            <IconButton onClick={() => onClose && onClose({}, "backdropClick")}>
              <ArrowBack />
            </IconButton>
            <IconButton onClick={() => onClose && onClose({}, "backdropClick")}>
              <Close />
            </IconButton>
          </Box>)}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box px={2} sx={{ width: "fit-content" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </MuiModal>
  )
}

export default Modal