import React, { useEffect, useState } from 'react'
import { Admin, Connector, DocRef, User } from '../../types/dreico.types'
import Box from '@mui/material/Box'
import { firebaseGetContacts } from '../../services/user'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ContactModal from './ContactModal'
import UserInfo from './UserInfo'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'
import { SxProps } from '@mui/material'

interface ContactCardProps {
  contactRef: DocRef
  contactProp?: User | Connector | Admin
  children?: JSX.Element
  networkFirst?: boolean
  hideButton?: boolean
  sx?: SxProps
}

const ContactCard = (props: ContactCardProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'contactBook' })
  const [contact, setContact] = useState<User | Connector | Admin>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (props.contactProp) {
      setContact(props.contactProp)
    }
    else {
      (async () => {
        const c = await firebaseGetContacts({ contacts: [props.contactRef] })
        setContact(c[0])
      })()
    }
  }, [props.contactProp, props.contactRef])

  return (
    <Box display="flex" justifyContent="space-between" sx={props.sx}>
      {contact && <UserInfo contact={contact}>
        <>
          {!props.hideButton &&
            <Button
              variant="text"
              color="primary"
              endIcon={<ArrowForward />}
              onClick={() => setOpen(true)}
              sx={{ mt: 1, transform: 'translateX(-1rem)' }}
            >
              {t("profileDetails.seeNetworkProfile")}
            </Button>}
          {contact && <ContactModal contact={contact} open={open} handleClose={() => setOpen(false)} networkFirst={props.networkFirst} />}
        </>
      </UserInfo>}
      <Box>
        {props.children}
      </Box>
    </Box >
  )
}

export default React.memo(ContactCard)
