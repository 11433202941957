import { auth } from './firebase'
import { sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth'
import { ErrorWithDetails } from "../utility/error-handling/error-handling-utility";

interface LoginData {
  email: string
  password: string
  setFirebaseUser: (user: User) => void
  onSuccess: () => void
  showErrorMessage: (error: any, userFriendlyMessage?: string | undefined) => void
}

export const FirebaseLogin = async (props: LoginData) => {

  const { email, password, setFirebaseUser, onSuccess, showErrorMessage } = props
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    setFirebaseUser(userCredential.user)
    onSuccess()
  }
  catch (error) {
    showErrorMessage(new ErrorWithDetails(error, "logging in"))
  }
}

interface ResetPasswordData {
  email: string
  onSuccess: () => void
  showErrorMessage: (error: any, userFriendlyMessage?: string) => void
}

export const FirebaseResetPassword = async (props: ResetPasswordData) => {
  const { email, onSuccess, showErrorMessage } = props
  try {
    await sendPasswordResetEmail(auth, email)
    onSuccess()
  }
  catch (error) {
    showErrorMessage(new ErrorWithDetails(error, "resetting password"))
  }
}