/**
 * Promisified waiting for changes.
 * @param { Function } f checker function 
 * @param { number } timeoutMs 
 * @param { number } interval 
 * @returns 
 */
const waitUntil = async (f: Function, timeoutMs: number, interval: number = 20) => {
  return new Promise<void>((resolve, reject) => {
    const timeWas = Date.now();
    const wait = setInterval(function() {
      if (f()) {
        clearInterval(wait);
        resolve();
      } else if ( Date.now() - timeWas > timeoutMs) {
        clearInterval(wait);
        reject();
      }
    }, interval);
  });
}

export default waitUntil