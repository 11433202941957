import waitUntil from "../async_handling/waitUntil"
import { ErrorWithDetails } from "../error-handling/error-handling-utility"

interface resizeProps {
  original_data: string | Blob
  desired_width?: number
  desired_height?: number
  max_width?: number
  max_height?: number
  imageType?: string
}

async function waitUntilImageIsLoaded(image: HTMLImageElement) {
  try {
    await waitUntil(() => image.complete, 2000)
  } catch (error) {
    throw new ErrorWithDetails(error, "ResizingError")
  }
} 

/**
 * Resize image data
 * Using the native Canvas Element and it's Context for compression and resizing
 */
async function resize(props: resizeProps) {
  let { 
    original_data,
    desired_width=200,
    desired_height=200,
    imageType = "image/jpeg"
  } = props
  const image = new Image()
  if (typeof original_data === 'string') {
    image.src = original_data
  }
  else {
    const objectUrl = URL.createObjectURL(original_data)
    image.src = objectUrl
  }
  // We need to wait until the browser loaded the image
  await waitUntilImageIsLoaded(image)

  let { width: original_width, height: original_height } = image

  const canvas = document.createElement('canvas')
  canvas.height = original_height
  canvas.width = original_width

  /**
   * Get the scale to apply to the image
   * */

  let scale = 1
  if ( original_width > original_height) {
    scale = desired_height / original_height
  }
  else {
    scale = desired_width / original_width
  }
  scale = scale <= 1 ? Math.round(scale * 1000) / 1000 : 1

  // Step 1: compression

  const ctx = canvas.getContext("2d");
  if (! ctx ) {
    throw new Error("ResizingError")
  }
  ctx.drawImage(image, 0, 0, original_width, original_height)

  const resized = canvas.toDataURL(imageType, scale)

  // Step 2: Resizing
  
  const new_image = new Image()
  new_image.src = resized
  await waitUntilImageIsLoaded(new_image)

  const new_width = original_width * scale
  const new_height = original_height * scale
  
  new_image.height = new_height
  new_image.width = new_width
  
  const new_canvas = document.createElement('canvas')
  new_canvas.height = new_height
  new_canvas.width = new_width
  
  const ctx2 = new_canvas.getContext("2d");
  if (! ctx2 ) {
    throw new Error("ResizingError");
  }
  ctx2.drawImage(new_image, 0, 0, new_width, new_height)
  const wrapped = new_canvas.toDataURL(imageType);

  // Step 3: clean up
  // remove dangling html elements
  new_image.remove()
  image.remove()
  new_canvas.remove()
  canvas.remove()
  
  return wrapped

}

export default resize