import React, {useContext, useEffect, useState} from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { Tag } from '../types/dreico.types'
import { createTagIfNotExists, getAllTags } from '../services/tags'
import { CustomAutocompleteProps } from '../types/forms.types'
import {ShowMessageContext} from "../contexts/ShowMessageContext";
import { TranslateFunction } from '../types/i18next'
import { useTranslation } from 'react-i18next'

interface TagInputProps extends CustomAutocompleteProps {
  value: Tag[]
  helperText?: string[] | string | false
}

const TagInput = (props: TagInputProps) => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.requestDescription' })
  const [tags, setTags] = useState<Tag[]>([])
  const { value, formik } = props
  const [inputValue, setInputValue] = useState<string>('')


  const { showErrorMessage } = useContext(ShowMessageContext)

  useEffect(() => {
    getAllTags({}).then((tags) => {
      setTags(tags || [])
    })
  }, [])

  const preventDefaultForCommas = (event: React.SyntheticEvent, iv: string, reason: string) => {
      if (iv.includes(',')) {
        const [newRawTag, rest] = iv.split(',')
        if (newRawTag) {
          const chosenTagNames = value.map((t)=>t.name).concat([newRawTag])
          plugableOnChange(chosenTagNames)
          setInputValue(rest)
        }
      }
      else {
        setInputValue(iv)
      }
  }

  const plugableOnChange = (chosenTagNames: string[]) => {
    const chosenTags = chosenTagNames.map(tagName => ({ name: tagName } as Tag))
    formik.setFieldValue('tags', chosenTags)
    chosenTags.forEach(formTag => {
      // Ensure that any tag entered by user exists in persistence layer.
      createTagIfNotExists({ newTag: formTag }).catch(error => {
        showErrorMessage(error)
      })
    });
  }

  const onChange = (event: React.ChangeEvent<any>, chosenTagNames: string[]) => plugableOnChange(chosenTagNames)

  return (
    <Autocomplete
      id="tags"
      multiple
      freeSolo
      options={tags.map(tag => tag.name) || []}
      value={value.map(tag => tag.name) || []}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={preventDefaultForCommas}
      renderInput={(params) => {
        return (
        <TextField
          name="tags"
          {...params}
          error={props.error}
          helperText={props.helperText}
          label={t("requestedProfile.requestedTags.requestedTagsPlaceholder")}
          placeholder=""
        />
      )}}
    />
  )
}

export default TagInput