import React, { createContext, useState } from 'react'
import { UserCredential } from 'firebase/auth'
import { User, Connector, Admin, ContactRequest, Connection } from '../types/dreico.types'

export interface UserContextType {
  user?: User | Connector | Admin
  setUser: (user?: User | Connector | Admin) => void
  firebaseUser?: UserCredential["user"]
  setFirebaseUser: (user?: UserCredential["user"]) => void
  requestsAsRequester: ContactRequest[]
  setRequestsAsRequester: (requests: ContactRequest[]) => void
  requestsAsConnector: ContactRequest[]
  setRequestsAsConnector: (requests: ContactRequest[]) => void
  connections: Connection[]
  setConnections: (connections: Connection[]) => void,
  resetUserData: () => void
}

const contextDefaultValues: UserContextType = {
  user: undefined,
  setUser: () => { },
  firebaseUser: undefined,
  setFirebaseUser: () => { },
  requestsAsRequester: [],
  setRequestsAsRequester: () => { },
  requestsAsConnector: [],
  setRequestsAsConnector: () => { },
  connections: [],
  setConnections: () => { },
  resetUserData: () => { }
}

export const UserContext = createContext(
  contextDefaultValues
)

export const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState(contextDefaultValues.user)
  const [firebaseUser, setFirebaseUser] = useState(contextDefaultValues.firebaseUser)
  const [requestsAsRequester, setRequestsAsRequester] = useState(contextDefaultValues.requestsAsRequester)
  const [requestsAsConnector, setRequestsAsConnector] = useState(contextDefaultValues.requestsAsConnector)
  const [connections, setConnections] = useState(contextDefaultValues.connections)

  const resetUserData = () => {
    setUser(contextDefaultValues.user)
    setFirebaseUser(contextDefaultValues.firebaseUser)
    setRequestsAsRequester(contextDefaultValues.requestsAsRequester)
    setRequestsAsConnector(contextDefaultValues.requestsAsConnector)
    setConnections(contextDefaultValues.connections)
  }


  return (
    <UserContext.Provider value={{
      user, setUser,
      firebaseUser, setFirebaseUser,
      requestsAsRequester, setRequestsAsRequester,
      requestsAsConnector, setRequestsAsConnector,
      connections, setConnections,
      resetUserData
    }}>
      {children}
    </UserContext.Provider>
  )
}
