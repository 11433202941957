import React from 'react'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useContext } from 'react'
import * as yup from 'yup'
import { UserContext } from '../contexts/UserContext'
import { firebaseSetUser } from '../services/user'
import { defaultBusinessTypes, BusinessType, Inudstries } from '../types/dreico.types'
import Box from '@mui/system/Box'
import LocationInput from '../atoms/LocationInput'
import IndustriesInput from '../atoms/IndustriesInput'
import TagsInput from '../atoms/TagsInput'
import FormikWithShowingErrors from "./FormikWithShowingErrors";
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../types/i18next'
import { ShowMessageContext } from '../contexts/ShowMessageContext'

interface BusinessFormProps {
  setEditMode: (editMode: boolean) => void
}

const BusinessForm = (props: BusinessFormProps) => {

  const { user } = useContext(UserContext)
  const { showSuccessMessage } = useContext(ShowMessageContext)
  const { setEditMode } = props

  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'profile' })

  const validationSchema = yup.object({
    businessType: yup.string().required(t('form.business.businessType.required')),
    companyName: yup
      .string(),
    jobPosition: yup
      .string(),
    location: yup
      .array()
      .min(1, t('form.business.locations.required')),
    industries: yup
      .array().of(
        yup.string()
      ).min(1, t('form.business.industries.required'))
      .max(10,),
    tags: yup
      .array().of(
        yup.object().shape({
          name: yup.string(),
        })
      ),
    description: yup
      .string()
      .max(1000, t('form.business.description.errorMax')),
  })

  return (
    <>
      <FormikWithShowingErrors
        initialValues={{
          businessType: user?.business?.businessOntology.businessType || '',
          companyName: user?.business?.name || '',
          jobPosition: user?.business?.position || '',
          location: user?.business?.businessOntology.location || [],
          industries: user?.business?.businessOntology.industries || [],
          tags: user?.business?.businessOntology.tags || [],
          description: user?.business?.description || '',
        }}

        validationSchema={validationSchema}
        onSubmit={async (values) => {
          user && await firebaseSetUser({
            values: {
              business: {
                _type: 'business',
                businessOntology: {
                  _type: 'businessOntology',
                  businessType: values.businessType as BusinessType,
                  // @ts-ignore untill location is dealt with
                  location: values.location,
                  industries: values.industries as Inudstries[],
                  tags: values.tags,
                },
                name: values.companyName,
                description: values.description,
                position: values.jobPosition,
                logo: ''
              }
            },
            actionType: 'updateBusinessData',
            user: user,
            onSuccess: () => {
              setEditMode(false)
              showSuccessMessage(t('form.business.successMessage'))
            }
          })
        }}
      >
        {(formik) => (
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.businessType.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.businessType.description')}
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ background: 'white', padding: '0 5px'}}>{t('form.business.businessType.placeaholder')}</InputLabel>
                    <Select
                      id="businessType"
                      name="businessType"
                      value={formik.values.businessType}
                      onChange={formik.handleChange}
                      error={formik.touched.businessType && Boolean(formik.errors.businessType)}
                    >
                      {defaultBusinessTypes.map((businessType) => (
                        <MenuItem key={businessType} value={businessType}>
                          {businessType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.name.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.name.description')}
                </Typography>
                <TextField
                  fullWidth
                  id='companyName'
                  name='companyName'
                  label={t('form.business.name.label')}
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.position.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.position.description')}
                </Typography>
                <TextField
                  fullWidth
                  id='jobPosition'
                  name='jobPosition'
                  label={t('form.business.position.label')}
                  value={formik.values.jobPosition}
                  onChange={formik.handleChange}
                  error={formik.touched.jobPosition && Boolean(formik.errors.jobPosition)}
                  helperText={formik.touched.jobPosition && formik.errors.jobPosition}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.locations.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.locations.description')}
                </Typography>
                <LocationInput
                  value={formik.values.location}
                  formik={formik}
                  error={formik.touched.location && Boolean(formik.errors.location)}
                  helperText={formik.touched.location && formik.errors.location as string | string[] | undefined}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.industries.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.industries.description')}
                </Typography>
                <IndustriesInput
                  value={formik.values.industries}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('industries', newValue)
                  }}
                  error={formik.touched.industries && Boolean(formik.errors.industries)}
                  helperText={formik.touched.industries && formik.errors.industries}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.description.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.description.description')}
                </Typography>
                <TextField
                  multiline
                  minRows={4}
                  fullWidth
                  id='description'
                  name='description'
                  label='Beschreibung'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={(formik.touched.description && formik.errors.description) || `${formik.values.description.length} / 1000`}
                />
              </CardContent>
            </Card>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h5" pb={1}>
                  {t('form.business.tags.title')}
                </Typography>
                <Typography variant="body2" pb={3}>
                  {t('form.business.tags.description')}
                </Typography>
                <TagsInput
                  value={formik.values.tags}
                  formik={formik}
                  error={formik.touched.tags && Boolean(formik.errors.tags)}
                  helperText={formik.touched.tags && formik.errors.tags as string[] | string | undefined}
                />
              </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={() => setEditMode(false)}>{t('form.formCancelButton')}</Button>
              <Box px={1} />
              <Button color="primary" variant="contained" type="submit">
                {t('form.formSaveButton')}
              </Button>
            </Box>
          </Box>
        )}
      </FormikWithShowingErrors>
    </>
  )
}

export default BusinessForm