import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import ContactRequestForm from '../../forms/ContactRequestForm'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'



const Kontaktanfrage = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request' })
  const steps = [ t('steps.stepOne'), t('steps.stepTwo')]
  const [activeStep, setActiveStep] = useState(0)

  return (
    <Box position="relative">
      <Typography variant="h4">
        {t("title")}
      </Typography>
      <Stepper activeStep={activeStep} sx={{ pt: 4, pb: 8, maxWidth: '24rem', margin: 'auto' }}>
        {steps.map((label) => (
          <Step>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ContactRequestForm step={activeStep} setStep={setActiveStep} />
    </Box>
  )
}

export default Kontaktanfrage