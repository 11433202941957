import React, { useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Chip from '@mui/material/Chip'
import Fab from '@mui/material/Fab'
import InviteModal from '../InviteModal'
import ContactSearch from '../ContactSearch'
import { TranslateFunction } from '../../types/i18next'
import { useTranslation } from 'react-i18next'


const ContactBook = () => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'contactBook' })

  const filters = useMemo(
    () => [
      {
        label: t('filters.all'),
        value: ''
      }, {
        label: t('filters.connectors'),
        value: 'connector'
      }, {
        label: t('filters.standard'),
        value: 'user'
      }
    ],
    [t]
  )

  const [activeFilter, setActiveFilter] = useState('')
  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  return (
    <Box>
      <Box>
        <Typography variant="h4" pb={2}>
          {t('title')}
        </Typography>
        <Typography variant="body1" pb={4}>
          {t('description')}
        </Typography>
        <Box width={'100%'} display="flex" justifyContent="flex-end">
          <Fab variant="extended" color="primary" onClick={() => setInviteModalOpen(true)} >
            <PersonAddIcon sx={{ pr: 1 }} />
            {t('buttonInviteContact')}
          </Fab>
          <InviteModal prompt={t('inviteContact.description')} open={inviteModalOpen} handleClose={() => setInviteModalOpen(false)} />
        </Box>
      </Box>
      <Box py={1.5}>
        <Typography variant="h6" pl={'2px'} pb={2}>
          {t('filters.instruction')}
        </Typography>
        {filters.map((filter) => (
          <Chip label={filter.label} sx={{ mr: 1, mb: 3 }} onClick={() => setActiveFilter(filter.value)} variant={activeFilter === filter.value ? 'filled' : 'outlined'} />
        ))}
      </Box>
      <ContactSearch filter={activeFilter} />
      {/* <ContactList filter={activeFilter} /> */}
    </Box >
  )
}

export default ContactBook