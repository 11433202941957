import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { CircularProgress } from '@mui/material'

interface AsyncSubmitButtonProps {
  label: string | ReactNode
  labelOnLoading?: string | ReactNode
  labelDisabled?: string
  disabled?: boolean
  isLoading: boolean
  variant: "contained" | "outlined" | "text"
  buttonProps?: ButtonProps
}

const AsyncSubmitButton = (props: AsyncSubmitButtonProps) => {
  const {
    label,
    labelOnLoading,
    labelDisabled,
    disabled,
    variant,
    isLoading,
    buttonProps
  } = props

  return (
    <Button
      variant={variant}
      disabled={disabled || isLoading}
      type="submit"
      {...buttonProps}
    >
      {
        disabled
          ? labelDisabled || label
          : ! isLoading 
            ? label 
            : labelOnLoading || <CircularProgress />}
    </Button>
  )
}

export default AsyncSubmitButton