import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { FormikProps } from 'formik'
import TextField from '@mui/material/TextField'
import TagsInput from '../../atoms/TagsInput'
import LocationInput from '../../atoms/LocationInput'
import IndustriesInput from '../../atoms/IndustriesInput'
import BusinessTypeInput from '../../atoms/BusinessTypeInput'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

interface RequestDescriptionFormProps {
  formik: FormikProps<{
    title: string;
    description: string;
    businessType: never[];
    location: never[];
    industries: never[];
    tags: never[];
  }>
}

const RequestDescriptionForm = (props: RequestDescriptionFormProps) => {
  const { t }: { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections.request.requestDescription' })
  const { formik } = props

  // prevent escape to submit form
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  useEffect(() => {
    //@ts-ignore
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      //@ts-ignore
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestTitle.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestTitle.description")}
          </Typography>
          <TextField
            fullWidth
            id="title"
            name="title"
            label={t("requestTitle.title")}
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />

        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestDescription.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestDescription.description")}
          </Typography>
          <TextField
            multiline
            minRows={4}
            fullWidth
            id="description"
            name="description"
            label={t("requestDescription.placeholder")}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={(formik.touched.description && formik.errors.description) || `${formik.values.description.length} / 2000`}
          />
        </CardContent>
      </Card>
      <Box pt={4} pb={2}>
        <Typography variant="h4" pb={2}>
          {t("requestedProfile.title")}
        </Typography>
        <Typography variant="body2" pb={2}>
          {t("requestedProfile.description")}
        </Typography>
      </Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestedProfile.requestedBusinessType.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestedProfile.requestedBusinessType.description")}
          </Typography>
          <BusinessTypeInput
            value={formik.values.businessType}
            onChange={(event, newValue) => {
              formik.setFieldValue('businessType', newValue)
            }}
            error={formik.touched.businessType && Boolean(formik.errors.businessType)}
            helperText={formik.touched.businessType && formik.errors.businessType} />
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestedProfile.requestedRegions.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestedProfile.requestedRegions.description")}
          </Typography>
          <LocationInput
            value={formik.values.location}
            formik={formik}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestedProfile.requestedIndustries.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestedProfile.requestedIndustries.description")}
          </Typography>
          <IndustriesInput
            value={formik.values.industries}
            onChange={(event, newValue) => {
              formik.setFieldValue('industries', newValue)
            }}
            error={formik.touched.industries && Boolean(formik.errors.industries)}
            helperText={formik.touched.industries && formik.errors.industries}
          />
        </CardContent>
      </Card>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" pb={2}>
            {t("requestedProfile.requestedTags.title")}
          </Typography>
          <Typography variant="body2" pb={2}>
            {t("requestedProfile.requestedTags.description")}
          </Typography>
          <TagsInput
            value={formik.values.tags}
            formik={formik}
            error={formik.touched.tags && Boolean(formik.errors.tags)}
            helperText={formik.touched.tags && formik.errors.tags}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default RequestDescriptionForm