import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../routes/Routes'
import Fab from '@mui/material/Fab'
import Connections from '../../components/Connections'
import { useTranslation } from 'react-i18next'
import { TranslateFunction } from '../../types/i18next'

const VernetzungenScreen = () => {
  const { t } : { t: TranslateFunction } = useTranslation('dreicoDe', { keyPrefix: 'connections' })

  return (
    <Box>
      <Typography variant="h4" pb={4}>
        {t('title')}
      </Typography>
      <Box width={'100%'} display="flex" justifyContent="flex-end" pb={4}>
        <Link to={AppRoutes.KONTAKTANFRAGE}>
          <Fab variant="extended" color="primary">
            <AddIcon sx={{ pr: 1 }} />
            {t('request.title')}
          </Fab>
        </Link>
      </Box>
      <Connections />
    </Box>
  )
}

export default VernetzungenScreen